import React, { useContext, useState } from "react";

import "antd/dist/antd.css";
import { Table } from "antd";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import { getAllVacationsUrl } from "../api/constants";
import { Context } from "../context/ContextProvider";

const SubTaskDatatable = (props) => {
  const [tagModal, setTagModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { deleteVacation } = useContext(Context);
  const showTagModal = (e) => {
    setTagModal(true);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setTagModal(false);
  };

  const deletevacationHandle = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(id);
        deleteVacation(id, getAllVacationsUrl)
        swal('Vacation deleted successfully.', {
          icon: "success",
        });
        setLoading(false);
        navigate('/vacations')
      }
    });
  };

  const data = props.data;
  let tableData = [];
  data?.map((item, i) => {
    let tempArray = {
      ...item,
      key: i,
      number: i + 1,
      action: (
        <>
          <Button
            onClick={() => {
              localStorage.setItem("requestCategory", JSON.stringify(item));
              navigate("/category-request/" + item?.id, {
                state: { vacation: item },
              });
            }}
            className="btn-sm btn-info"
            style={{
              cursor: "pointer",
            }}
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
          
          <Button
            onClick={() => {
              deletevacationHandle(item?.id);
            }}
            className="btn-sm btn-danger"
            style={{
              cursor: "pointer",
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </>
      ),
    };
    tableData.push(tempArray);
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <>
      <Table
        rowSelection={rowSelection}
        columns={props.columns}
        dataSource={tableData}
      />
      <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Contact Us Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Full Name:</strong>
              </div>
              <div className="mx-2 flex2">Test User</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2 flex2">Something@gmail.com</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Subject:</strong>
              </div>
              <div className="mx-2 flex2">Something Something</div>
            </div>

            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Phone Number:</strong>
              </div>
              <div className="mx-2 flex2">+92 301 309 8556</div>
            </div>

            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Message:</strong>
              </div>
              <div className="mx-2 flex2">
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, Lorem Ipsum is simply dummy text of
                the printing and typesetting industry.
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubTaskDatatable;
