import React, { useState } from 'react'
import HashLoader from "react-spinners/HashLoader";
const Loader = (props) => {
    const [color, setColor] = useState("#002866");
    const {loading}=props
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
  return (
    <HashLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
  )
}

export default Loader