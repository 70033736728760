import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Footer from "./footer";
import Header from "../component/header";
import Sidemenu from "../component/sidemenu";
import Jumbotran from "../component/Jumbotran";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import api from "../api/api";
import { getAllAdminsUsers, getAllGmUsers } from "../api/constants";
import { toast } from "react-toastify";
import { ucWords } from "../utils/util";
import Loader from "../component/Loader";
import { Context } from "../context/ContextProvider";

const Gms = () => {
  //States
  const [gms, setGms] = useState([]);
  const [tagModal, setTagModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(false);
  const { deleteRecord } = useContext(Context);
  const showTagModal = (data) => {
    setModalData(data);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const navigate = useNavigate();
  const deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const filtered_users = [...gms];
        let array = filtered_users.filter((item) => {
          return item.id !== id;
        });
        setGms(array);
        deleteRecord(id, getAllAdminsUsers)
          .then((res) => {
            swal("Poof! GM User has been deleted!", {
              icon: "success",
            });
            setLoading(false);
          })
          .catch((res) => {
            swal("Something went wrong.", {
              icon: "error",
            });
          });
        setLoading(false);
      }
    });
  };
  const getGms = async () => {
    // GET request for remote image in node.js
    setLoading(true);
    try {
      let response = await api.authGet(
        `${process.env.REACT_APP_BASE_URL}${getAllGmUsers}`
      );
      if (response.status !== 200) toast.error(response.data.message);
      else {
        console.log(response.data);
        setGms(response.data.data);
      }
    } catch (error) {
      console.log({ error });
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log("use effect");
    getGms();
  }, []);
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="GM Users" desc="GM Users" user="gm/users" />
              <div className="page-inner">
                {/* <h4 className="page-title">User</h4> */}

                {!loading ? 
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-hover table-head-bg-primary table-sm-responsive mt-2">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Full Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Role</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gms && gms?.length > 0 ? (
                          <>
                            {gms.map((item, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{ucWords(item.name)}</td>
                                  <td>{item.email}</td>
                                  <td>{ucWords(item.role)}</td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        navigate("/admin/user/edit", {
                                          state: { user: item },
                                        })
                                      }
                                      className="p-2 btn-warning"
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </span>
                                    <span
                                      className="p-2 btn-info"
                                      onClick={() => showTagModal(item)}
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </span>
                                    <span
                                      onClick={(e) => deleteUser(item.id)}
                                      className="p-2 btn-danger"
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </span>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr className="text-center">
                              <td colSpan="5">No GMS available.</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                : 
                <Loader />
                }
              </div>
            </div>

            <Footer />
          </div>

          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>User Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Full Name:</strong>
                  </div>
                  <div className="mx-2">{modalData.name}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Email:</strong>
                  </div>
                  <div className="mx-2">{modalData.email}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Role:</strong>
                  </div>
                  <div className="mx-2">{"GM"}</div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Gms;
