import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import * as Yup from "yup";

import EditComponent from "../../component/formElements/EditComponent";
import {Context} from "../../context/ContextProvider";

const EditCategory = (props) => {
    const navigate = useNavigate();
    const {
        state: {category},
    } = useLocation();
    const {EditAdminUser} = useContext(Context);
    const {updateCategories, getCategories} = useContext(Context);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const [fields, setFields] = useState([]);
    const [alreadyAdded, setAlreadyAdded] = useState([]);
    const [fieldIndex, setFieldIndex] = useState(0);
    const [initialValues, setInitialValues] = useState({
        name: category.name ?? "",
        parent_id: category.parent_id,
    });
    const [validationSchemaRaw, setValidationSchemaRaw] = useState({
        name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Name is Required"),
        parent_id: Yup.number(),
    });
    const mapFields = (fields) => {
        return fields.map(function (item, key) {
            let labelField = `fields_label_${key + 1}`;
            let labelType = `fields_type_${key + 1}`;
            setFieldIndex(key + 2)
            return {
                type: "custom",
                fieldIndex: key + 1,
                fields: [
                    {
                        name: labelField,
                        label: "Field Label",
                        placedHolder: "Field Label",
                        type: "text",
                        value: item.label
                    },
                    {
                        name: labelType,
                        label: "Field Type",
                        readonly: true,
                        type: "text",
                        value: item.type
                    }
                ]
            }
        })
    }
    const mapInitials = (fields) => {
        return fields.map(function (item, key) {
            let labelField = `fields_label_${key + 1}`;
            let labelType = `fields_type_${key + 1}`;
            return {
                [labelField]: item.label,
                [labelType]: item.type
            }
        })
    }

    const mapValidationSchema = (fields) => {
        return fields.map(function (item, key) {
            let labelField = `fields_label_${key + 1}`;
            let labelType = `fields_type_${key + 1}`;
            setValidationSchemaRaw((object) => ({
                ...object,
                [labelField]: Yup.string(),
                [labelType]: Yup.string()
            }))
            return {
                [labelField]: Yup.string(),
                [labelType]: Yup.string()
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        getCategories().then((res) => {
            setCategories([...res.data.data]);
            if (category.fields.length) {
                let new_fields = mapFields(category.fields)
                let map_Initials = mapInitials(category.fields)
                let mappedInitials = {};
                map_Initials.map((item)=>{
                    mappedInitials = { ...mappedInitials,...item}
                })
                console.log({mappedInitials})
                let map_ValidationSchema = [...mapValidationSchema(category.fields)]
                setFields(
                    [
                        {name: "name", label: "Name", type: "text"},
                        {
                            name: "parent_id",
                            label: "Parent",
                            type: "select",
                            options:
                                [...res.data.data] && [...res.data.data].map((category__, key) => (
                                    {_id: category__.id, name: category__.name, value: category__.id}
                                ))
                            ,
                        },
                        {
                            name: "field_type",
                            label: "New Field",
                            type: "select",
                            options:
                                [
                                    {_id: 1, name: "Text Field", value: "text"},
                                    {_id: 2, name: "Number Field", value: "number"},
                                    {_id: 3, name: "Date Field", value: "date"},
                                    {_id: 4, name: "Time Field", value: "time"},
                                    {_id: 6, name: "Text Area Field", value: "textarea"},
                                    {_id: 7, name: "Single Image", value: "image"},
                                    {_id: 8, name: "Multiple Images", value: "images"},
                                ]
                            ,
                        },
                        ...new_fields
                    ]
                )
                setInitialValues({
                    ...initialValues,
                    ...mappedInitials
                })
            } else {
                setFields(
                    [
                        {name: "name", label: "Name", type: "text"},
                        {
                            name: "parent_id",
                            label: "Parent",
                            type: "select",
                            options:
                                [...res.data.data] && [...res.data.data].map((category__, key) => (
                                    {_id: category__.id, name: category__.name, value: category__.id}
                                ))
                            ,
                        },
                        {
                            name: "field_type",
                            label: "New Field",
                            type: "select",
                            options:
                                [
                                    {_id: 1, name: "Text Field", value: "text"},
                                    {_id: 2, name: "Number Field", value: "number"},
                                    {_id: 3, name: "Date Field", value: "date"},
                                    {_id: 4, name: "Time Field", value: "time"},
                                    {_id: 6, name: "Text Area Field", value: "textarea"}
                                ]
                            ,
                        },
                    ]
                )
            }
            setLoading(false)

        })
    }, [])
    const state = {
        data: {
            initialValues: initialValues,
            validationSchema: Yup.object().shape({
                ...validationSchemaRaw
            }),
            submitHandler: async (values) => {
                const data = {...values}
                let filtered_keys = (obj, filter) => {
                    let key, keys = []
                    for (key in obj)
                        if (obj.hasOwnProperty(key) && filter.test(key))
                            keys.push(obj[key])
                    return keys
                }
                data['filteredLabels'] = filtered_keys(data, /fields_label/)
                data['filteredTypes'] = filtered_keys(data, /fields_type/)

                const response = await updateCategories(category.id, data)
                if (response.status === 200) {
                    navigate("/category", {state: {toastmessage: response.data.message}})
                } else {
                    toast.error("Something went wrong")
                }
            },
            inputs: fields,
            addField: {
                label: "Add Field",
                clickHandler: function (params = '') {
                    if (!(
                        Object.values(alreadyAdded).includes("image") && params.values.field_type === 'image' ||
                        Object.values(alreadyAdded).includes("images") && params.values.field_type === 'images'
                    )) {

                        let labelField = `fields_label_${fieldIndex}`;
                        let labelType = `fields_type_${fieldIndex}`;
                        let object = {
                            type: "custom",
                            fieldIndex: fieldIndex,
                            fields: [
                                {
                                    name: labelField,
                                    label: "Field Label",
                                    placedHolder: "Field Label",
                                    type: "text",
                                    value: "Field Label for " + params.values.field_type
                                },
                                {
                                    name: labelType,
                                    label: "Field Type",
                                    readonly: true,
                                    type: "text",
                                    value: params.values.field_type
                                }
                            ]
                        }
                        let initVals = {
                            ...params.values,
                            [labelField]: "Field Label for " + params.values.field_type,
                            [labelType]: params.values.field_type
                        }

                        setFields([...state.data.inputs, object])
                        setInitialValues(initVals)

                        setValidationSchemaRaw((object) => ({
                            ...object,
                            [labelField]: Yup.string(),
                            [labelType]: Yup.string()
                        }))

                        setFieldIndex(fieldIndex + 1)
                        setAlreadyAdded([...alreadyAdded, params.values.field_type])
                    }
                    else
                    {
                        alert("Cant add Single Image or Multiple Images more then once")
                    }
                }
            },
            submitButtonLabel: "Update Category",
        },
        loading: loading
    };
    return (
        <>
            <ToastContainer/>
            <EditComponent
                page={"Edit Category"}
                desc={"Edit Category"}
                level={
                    [{text: "Categories", link: "/category"}]
                }
                state={state}
            />
        </>
    );
};

export default EditCategory;
