import React, { useContext } from "react";
import Profile from "../assets/img/profile.jpg";
import Logo from "../assets/img/logowhite.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Context } from "../context/ContextProvider";

const Header = () => {
  const navigate = useNavigate();
  const { setUserData } = useContext(Context);
  const userData = JSON.parse(localStorage.getItem("userData"));
  return (
    <div className="main-header">
      <div className="logo-header" data-background-color="blue">
        <button
          className="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="icon-menu"></i>
          </span>
        </button>
        <button className="topbar-toggler more">
          <i className="icon-options-vertical"></i>
        </button>
        <div className="nav-toggle">
          <button className="btn btn-toggle toggle-sidebar">
            <i className="icon-menu"></i>
          </button>
        </div>
      </div>

      <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="blue2"
      >
        <div className="container-fluid">
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li className="nav-item dropdown hidden-caret">
              <a
                className="dropdown-toggle profile-pic"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <div className="avatar-sm">
                  <img
                    src={
                      userData?.data?.profile_pic
                        ? userData?.data?.profile_pic
                        : Profile
                    }
                    alt="header profile picture"
                    className="avatar-img rounded-circle"
                  />
                </div>
              </a>
              <ul className="dropdown-menu dropdown-user animated fadeIn">
                <div className="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div className="user-box">
                      <div className="" style={{ height: "50px", width: '50px'}}>
                        <img
                          src={
                            userData?.data?.profile_pic
                              ? userData?.data?.profile_pic
                              : Profile
                          }
                          alt="image profile"
                          className="avatar-img rounded"
                        />
                      </div>
                      <div className="u-text">
                        <h4>{userData?.data?.name}</h4>
                        <p className="text-muted">{userData?.data?.email}</p>
                        <Link
                          to={"/profile"}
                          className="btn btn-xs btn-secondary btn-sm"
                        >
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                    <Link to={"/profile"} className="dropdown-item">
                      My Profile
                    </Link>

                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to={"/setting"}>
                      Account Setting
                    </Link>
                    <div className="dropdown-divider"></div>
                    <button
                      onClick={() => {
                        localStorage.removeItem("userData");
                        setUserData({});
                        localStorage.clear();
                        navigate("/");
                      }}
                      className="dropdown-item"
                    >
                      Logout
                    </button>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
