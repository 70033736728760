import React, { useState } from "react";
import Datatable from "../component/Datatable";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import { toast } from "react-toastify";

const TermCondition = () => {
  return (
    <div className="App">
      <div className="">
        <div className="panel-header bg-primary-gradient">
          <div className="page-inner">
            <div className=" text-center ">
              <h1 className="text-white mb-0 fw-bold">Term and Condition</h1>
            </div>
          </div>
        </div>

        <div className="page-inner container py-5 my-5">
          <b>
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE PURCHASING OR
            USING THE PRODUCTS AND SERVICES OFFERED BY RICH VENTURES LLC.
            INTRODUCTION; SUBSCRIPTION
          </b>
          <br />
          <br />

          <p>
            By accessing or using The House App hosted services, products,
            content, data, or other features and functionality made available to
            you by The House App (together, the “Services”), you are agreeing to
            comply with these terms of service and all policies and guidelines
            we make available to users of the Services from time to time,
            including without limitation our Privacy Policy. These Terms of
            Service are a binding agreement between The House App and you or the
            entity you represent (“you” or “your”).
          </p>
          <br />

          <p>
            The House App reserves the right to modify, supplement, amend,
            revise or otherwise change any of these Terms of Service at any
            time, but only on a prospective, not retroactive, basis. The House
            App will use commercially reasonable efforts to provide you thirty
            (30) days advance notice through your invoice or by e-mail to the
            most recent email address associated with your account of any such
            changes to these Terms of Service that would have a materially
            adverse effect on you. Changes will become effective on the next
            business day following the 30-day notice perioTerm and Conditionif you are registering
            on behalf of an entity, that you are authorized to enter into, and
            bind the entity to, these Terms of Service and register for the
            Services. The Services are not available to individuals who are
            younger than 13 years old. The House App may, in its sole
            discretion, refuse to offer the Services to any person or entity and
            change its eligibility criteria at any time. You are solely
            responsible for ensuring that these Terms of Service are in
            compliance with all laws, rules, and regulations applicable to you
            and the right to access the Services is revoked where these Terms of
            Service or use of the Services is prohibited and, in such
            circumstances, you agree not to use or access the Site or Services
            in any way.
          </p>
          <br />
          <p>
            If you have entered into a signed order form, service order,
            subscription agreement, trial agreement, or other ordering document
            that specifies your purchase of Services, pricing, and related terms
            (a “Subscription Agreement”), that Subscription Agreement together
            with these Terms of Service governs your access to and use of the
            Services and supersedes any separate discussions or representations
            on this topic, including any separate terms you may propose in
            connection with a purchase order or otherwise. The Subscription
            Agreement will be deemed to apply in case of any conflict between
            these Terms of Service and the Subscription Agreement.
          </p>
          <br />

          <b>GRANT OF LICENSE; RESTRICTIONS</b>
          <br />
          <p>
            Subject to your compliance with these Terms of Service, React mobile
            hereby grants to you, during the period of time stated in any
            Subscription Agreement, a limited, personal, revocable,
            non-exclusive, non-transferable, non-sublicensable right and license
            to access and use the Services for your internal business purposes.
            Your access to or use of the Services is subject to the following
            obligations, restrictions, and limitations:
          </p>
          <br />
          <p>
            You will comply with: (i) all instructions and requirements in any
            documentation for the Services that The House App may provide or
            make available to you (the “Documentation”); and (ii) all local,
            state, national, and international laws and regulations (as each of
            these may be amended or modified from time to time) applicable to
            your access to or use of the Services.
          </p>
          <br />
          <p>
            Except as may be expressly permitted in these Terms of Service, you
            shall not: (i) license, sublicense, sell, lease, rent, loan,
            timeshare, distribute, act as a service bureau or managed service,
            publicly communicate, disclose, permit access to, or transfer to any
            third party, the Services, or any portion thereof, whether for
            profit or without charge; (ii) modify, alter, tamper with, repair or
            otherwise create derivative works of the Services; (iii) translate,
            reverse engineer, disassemble, or decompile the Services, apply any
            other process or procedure to derive the source code of any software
            included in the Services, or otherwise determine or attempt to
            determine how the Services work or operate; (iv) remove any
            copyright and other proprietary notices placed upon the Services;
            (v) circumvent any use-limitation or protection device contained in
            or placed upon the Services or access or attempt to access any
            portion of the Services that you are not authorized to access; (vi)
            use the Services to create products or perform services which
            compete or interfere with those of The House ; or (vii) upload,
            transmit, or otherwise publish any communication or content through
            the Services that: (a) infringes or misappropriates the intellectual
            property or other proprietary rights of any thTerm and Conditionird party; or (b)
            contains software viruses or any other malicious code.
          </p>
          <br />
          <p>
            You acknowledge and agree that the Services are licensed and not
            sold. The Services are owned by The House App and/or its licensors
            and are protected by United States trademark and copyright laws and
            international treaty provisions. The House App or its licensors own
            and retain all rights, title, and interest in and to the Services,
            including any and all patents, trademarks, copyrights, trade
            secrets, and other intellectual property rights embodied or
            contained therein. Your possession or use of Services does not
            transfer to you any right, title, or interest in any of the
            foregoing and you will not acquire any such right, title, or
            interest, except as expressly set forth in these Terms of Service.
          </p>
          <br />
          <p>
            You hereby grant The House App, at no charge, the right to use,
            disclose, reproduce, license, distribute and exercise all other
            rights in any comments, feedback, or other input (“Feedback”) you
            provide to us regarding the Services. Your Feedback is entirely
            voluntary and is provided as-is and without any warranties.
          </p>
          <br />
          <b>CONNECTIVITY, COMMUNICATIONS, PRIVACY</b>
          <br /><br />
          <p>
            You must have an internet-enabled cell phone or device using either
            the iOS or Android operating system in order to access the Services.
            Normal carrier charges and taxes may apply to any content you obtain
            from the Services. The House App is not responsible for any
            surcharges you incur from your mobile phone or internet service
            provider as a result of the use of the Services.
          </p>
          <br />
          <b>CONFIDENTIALITY</b>
          <br /><br />
          <p>
            You (as the “Recipient”) may, during the course of its provision
            and/or use of the Services, receive, have access to, and acquire
            knowledge from discussions with us (as the “Discloser”) which may
            not be accessible or known to the general public, such as technical
            and business information concerning hardware, software, designs,
            specifications, techniques, processes, procedures, research,
            development, projects, products or services, business plans or
            opportunities, business strategies, finances, costs, customers,
            vendors, test results and security information (“Confidential
            Information”). Confidential Information shall not include, and shall
            cease to include, as applicable, information or materials that (a)
            are generally known to the public; (b) become generally known to the
            public, other than as a result of the act or omission of the
            Recipient; (c) were rightfully known to the Recipient prior to its
            receipt thereof from the Discloser; (d) are or were disclosed by the
            Discloser generally without restriction on disclosure; (e) the
            Recipient lawfully received from a third party without that third
            party’s breach of agreement or obligation of trust; or (f) are
            independently developed by the Recipient as shown by documents and
            other competent evidence in the Recipient’s possession.
          </p>
          <br />
          <p>
            The Recipient shall not: (i) use any Confidential Information of the
            Discloser for any purpose outside the scope of these Terms of
            Service, except with the Discloser’s prior written permission, or
            (ii) disclose or make the Discloser’s Confidential Information
            available to any party, except those of its employees, contractors,
            and agents that have signed an agreement containing the disclosure
            and use provisions substantially similar to those set out herein or
            are otherwise under similar binding obligations of confidentiality
            and have a “need to know” in order to carry out the purpose of this
            Agreement.
          </p>
          <br />
          <p>
            You agree to protect the confidentiality of our Confidential
            Information in the same or similar manner that you protect the
            confidentiality of your own proprietary and confidential information
            of like kind, but in no event shall you exercise less than
            reasonable care in protecting such Confidential Information. If the
            Recipient is compelled by law to disclose Confidential Information
            of the Discloser, it shall provide the Discloser with prior notice
            of such compelled disclosure (to the extent legally permitted) and
            reasonable assistance, at Discloser’s cost, if the Discloser wishes
            to contest the disclosure.
          </p>
          <br />
          <p>
            Due to the unique nature of The House App Confidential Information
            disclosed hereunder, there can be no adequate remedy at law for the
            Recipient’s breach of its obligations hereunder, and any such breach
            may result in irreparable harm to the Discloser. Therefore, upon any
            such breach or threat thereof, the Discloser shall be entitled to
            seek injunctive and other appropriate equitable relief in addition
            to any other remedies available to it, without the requirement of
            posting a bond.
          </p>
          <br />

          <b>COPYRIGHT; TRADEMARKS</b>
          <br /><br />
          <p>
            You acknowledge that all materials on the Services, including the
            Services’ design, graphics, text, sounds, pictures, software, and
            other files and the selection and arrangement thereof (collectively,
            “Materials”), are the property of The House App or its licensors,
            and are subject to and protected by United States and international
            copyright and other intellectual property laws and rights. You will
            not obtain any ownership interest in the Materials or the Services
            through these Terms of Service or otherwise. All rights to Materials
            not expressly granted in these Terms of ServicTerm and Conditione are reserved to
            their respective copyright owners. Except as expressly authorized by
            these Terms of Service or on the Services, you may not copy,
            reproduce, distribute, republish, download, perform, display, post,
            transmit, exploit, create derivative works, or otherwise use any of
            the Materials in any form or by any means, without the prior written
            authorization of The House App or the respective copyright owner.
            The House App authorizes you to view and download the Materials only
            for personal and internal business use, provided that you keep
            intact all copyright and other proprietary notices contained in the
            original Materials. You may not modify or adapt the Materials in any
            way or otherwise use them for any public or commercial purposes. The
            trademarks, service marks, trade names, trade dress, and logos
            (collectively, “Marks”) contained or described on the Services
            (including, without limitation, The House App and any Marks
            associated with any products available on the Service) are the sole
            property of The House App and/or its licensors and may not be
            copied, imitated or otherwise used, in whole or in part, without the
            prior written authorization of The House App and/or licensors. In
            addition, all page headers, custom graphics, button icons, and
            scripts are Marks of The House App and may not be copied, imitated,
            or otherwise used, in whole or in part, without the prior written
            authorization of The House App will enforce its intellectual
            property rights to the fullest extent of the law.
          </p>
          <br />
          <b>ALERT DISCLAIMER</b>
          <br /><br />
          <p>
            You understand and agree that any alerts provided to you through the
            Service may be delayed or prevented by a variety of factors. The
            House App does its best to provide alerts in a timely manner with
            accurate information. However, we neither guarantee the delivery nor
            the accuracy of the content of any alert. You also agree that The
            House App shall not be liable for any delays, failure to deliver, or
            misdirected delivery of any alert; for any errors in the content of
            an alert; or for any actions taken or not taken by you or any third
            party in reliance on an alert.
          </p>
          <br />

          <b>ONLINE AND MOBILE ALERTS</b>
          <br /><br />
          <p>
            The House App may from time to time provide automatic alerts and
            voluntary account-related alerts. Automatic alerts are sent to you
            following certain changes made online to your The House App account,
            such as a change in your registration information. You do not need
            to activate these alerts. Although you may have the option to
            suppress some of these automatic alerts, we strongly recommend that
            you do not since they are security-related. Voluntary account alerts
            may be turned on by default as part of the Services. They may then
            be customized, deactivated, or reactivated by you. These alerts
            allow you to choose alert messages for your accounts. The House App
            may add new alerts from time to time, or cease to provide certain
            alerts at any time upon its sole discretion. Each alert has
            different options available, and you may be asked to select from
            among these options upon activation of your alerts service.
            Electronic alerts will be sent to the email address you have
            provided as your primary email address. If your email address or
            your mobile device’s email address changes, you are responsible for
            informing us of that change. You can also choose to have alerts sent
            to a mobile device that accepts text messages. Changes to your email
            address or mobile number will apply to all of your alerts. Because
            alerts are not encrypted, we will never include your passcode.
            However, alerts may include your The House App login ID and some
            information about your accounts. Depending upon which alerts you
            select, information such as an account balance or the due date for
            your payment may be included. Anyone with access to your email will
            be able to view the content of these alerts. At any time you may
            disable future alerts.
          </p>
          <br />
          <b>ENTIRE AGREEMENT</b>
          <br /><br />
          <p>
            These Terms of Service, including the Privacy Policy and other
            policies incorporated herein, constitute the entire and only
            agreement between The House App and each user of the Services with
            respect to the subject matter of these Terms of Service and
            supersedes any and all prior or contemporaneous agreements,
            representations, warranties, and understandings, written or oral,
            with respect to the subject matter of these Terms of Service.
          </p>
          <br />

          <b>MISCELLANEOUS</b> <br /><br />
          <p>
            The failure of The House App and its Affiliated Parties to insist
            upon strict adherence to any term of these Terms of Service shall
            not constitute a waiver of such term and shall not be considered a
            waiver or limit that party’s right thereafter to insist upon strict
            adherence to that term or any other term of these Terms of Service.
          </p>
          <br />

          <p>
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising from or relating to the use of the
            Services or these Terms of Service must be filed within one year
            after such claim or cause of action arose, or will be forever
            barred. The “Disclaimer; Limitation of Liability” provisions of
            these Terms of Service are for the benefit of The House App and its
            Affiliated Parties as defined herein, and each of these individuals
            or entities shall have the right to assert and enforce these
            provisions directly against you on its own behalf.
          </p>
          <br />

          <b>LANGUAGE</b>
          <br /><br />
          <p>
            Where The House App has provided you with a translation of the
            English language version of these Terms of Service, then you agree
            that the translation is provided for your convenience only and that
            the English language versions of the Terms will govern your
            relationship with The House App.
          </p>
          <br />
          <p>
            If there is any contradiction between what the English language
            version of these Terms of Service says and what a translation says,
            then the English language version shall take precedence.
          </p>
          <br />
          <b>CONTACT INFORMATION</b>
          <br /><br />
          <p>
            If you have any comments, questions, or complaints regarding these
            Terms of Service or the Services, or wish to report any violation of
            these Terms of Service, please contact us at [richardyu@gmail.com].
            We will address any issue to the best of our abilities as soon as
            possible.
          </p>
          <br /><br />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermCondition;
