import React, {useContext, useState} from 'react'
import {useLocation, useNavigate} from "react-router";
import * as Yup from "yup";
import {toast} from "react-toastify";


import EditComponent from '../../component/formElements/EditComponent'
import {Context} from '../../context/ContextProvider';

const EditUser = (props) => {
    const {state: {user}} = useLocation()
    const [data, setdate] = useState({})
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const {updateUserProfile} = useContext(Context);
    console.log({user});
    const editState = {
        data: {
            initialValues: {
                name: user?.name,
                email: user?.email,
            },
            validationSchema: Yup.object().shape({
                name: Yup.string()
                    .min(2, "Too Short!")
                    .max(50, "Too Long!")
                    .required("Username is Required"),
                email: Yup.string()
                    .email("Invalid email")
                    .required("Email is Required")
                    .min(2, "Too Short!")
                    .max(50, "Too Long!"),
            }),
            submitHandler: async (values) => {
                const data = {...values, user_id: user.id}
                // same shape as initial values
                const response = await updateUserProfile(data)
                console.log({response})
                if (response.status === 200) {
                    navigate("/app/users", {state: {toastmessage: response.data.message}})
                } else {
                    toast.error("Something went wrong")
                }
            },
            inputs: [
                {name: "name", label: "Name", type: "text"},
                {name: "email", label: "Email", type: "email"},
            ],
            submitButtonLabel: "Update",
        },
        loading:loading
    };
    const getAddAdminUserHandler = () => {

    }
    return (
        <>
            <EditComponent state={editState} page={"Edit User"} level={[{link: "/app/users", text: "App Users"}]}/>
        </>
    )
}

export default EditUser