import React, { useContext, useEffect, useState } from "react";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import Main from "../component/main";
import Sidemenu from "../component/sidemenu";
import Footer from "./footer";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import Datatable from "../component/Datatable";
import { Context } from "../context/ContextProvider";
import AppUserDatatable from "../component/AppUserDataTable";
import Loader from "../component/Loader";
import swal from "sweetalert";
import { deleteUserURL } from "../api/constants";
import { Button, Modal } from 'react-bootstrap';

const Home = (props) => {
  const { state } = useLocation();

  const [loading1, setLoading1] = useState(true);
  const [load, setLoad] = useState(true);
  const [user, setUser] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAllVacations, deleteRecord, getAppUsers } = useContext(Context);
  useEffect(() => {
    if (state) {
      toast.success(state.toastmessage);
    }
  }, []);
  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    setLoad(true)

    getAllVacations().then((res) => {
      let array = res?.data?.data?.reverse();
      setVacations(array);
      setLoading(false);
      setLoad(false)
    });
    if (userData?.data?.role === "admin") {
      getAppUsers().then((res) => {
        setUser(res?.data?.data);
        setLoading(false);
      setLoad(false)

      });
    }

    return () => {
      controller.abort();
    };
  }, []);

  console.log(user);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const columns = [
    {
      title: "#",
      dataIndex: "sr",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "User",
      dataIndex: "user",
    },
    {
      title: "Assigned To",
      dataIndex: "assignto",
    },
    {
      title: "Destination",
      dataIndex: "destination",
    },
    {
      title: "Vibe",
      dataIndex: "vibe",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Guest",
      dataIndex: "guestCount",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const columns1 = [
    {
      title: "#",
      dataIndex: "sr",
    },
    {
      title: "Full Name",
      dataIndex: "fname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },

    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const [tagModal, setTagModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    if (state) {
      console.log({ state });
      toast.success(state.toastmessage);
    }
  }, []);

  const showTagModal = (data) => {
    console.log({ data });
    setModalData(data);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const navigate = useNavigate();

  const deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteRecord(id, deleteUserURL)
          .then((res) => {
            if (res.status == 200) {
              swal(res.data.message, {
                icon: "success",
              });
              setLoading(false);
              console.log({ res });
              getAppUser();
            }
            if (res.status == 400) {
              swal(res.data.message, {
                icon: "warning",
              });
              setLoading(false);
            }
          })
          .catch((res) => {
            swal("Something went wrong.", {
              icon: "error",
            });
          });
        setLoading(false);
      }
    });
  };

  const getAppUser = async () => {
    setLoading1(true);
    let response = await getAppUsers();
    setUser(response?.data?.data);
    setLoading1(false);
  };
  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        setLoading(true);
        getAppUser();
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

 

  return (
    <>
      <ToastContainer />
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />

          <div className="main-panel">
            <div className="container">
              <Jumbotran page="Dashboard" desc="" />

              {!load ? (
                <>
                  <div className="mx-4 mt-4">
                    <h3>All Vacations</h3>
                    <Datatable columns={columns} data={vacations} />
                  </div>

                  {userData?.data?.role === "admin" && (
                    <div className="mx-4">
                      <h3>App Users</h3>
                      <table className="table table-hover table-head-bg-primary table-sm-responsive mt-2">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user && user?.length > 0 ? (
                            <>
                              {user.map((u, i) => (
                                <tr key={u.id}>
                                  <td>{i + 1}</td>
                                  <td>{u.name}</td>
                                  <td>{u.email}</td>
                                  <td>{u.role ?? "N/A"}</td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        navigate("/app/user/edit-user", {
                                          state: { user: u },
                                        })
                                      }
                                      className="p-2 btn-warning"
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </span>
                                    <span
                                      className="p-2 btn-info"
                                      onClick={() => showTagModal(u)}
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </span>
                                    <span
                                      onClick={(e) => deleteUser(u.id)}
                                      className="p-2 btn-danger"
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr className="text-center">
                                <td colSpan="5">No user available.</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              ) : (
                <div className="mt-5">
                  <Loader />
                </div>
              )}
            </div>

            <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>User Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex">
              <div className="mb-1">
                <strong>Full Name:</strong>
              </div>
              <div className="mx-2">{modalData.name}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2">{modalData.email}</div>
            </div>
            <div className="d-flex">
              <div className="mb-1">
                <strong>Role:</strong>
              </div>
              <div className="mx-2">{modalData.role}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
