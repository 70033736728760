import React, { useState } from "react";

import "antd/dist/antd.css";
import { Table } from "antd";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router";

const Datatable = (props) => {
  const [tagModal, setTagModal] = useState(false);
  const showTagModal = (e) => {
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const navigate = useNavigate();

  const deleteUser = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your user has been deleted!", {
          icon: "success",
        });
      }
    });
  };

  const data = [];

  for (let i = 0; i < props?.data?.length; i++) {
    data.push({
      sr: i + 1,
      title: props?.data[i]?.title,
      user: props?.data[i]?.user ?? "N/A",
      assignto: props?.data[i]?.admin ?? "N/A",
      destination: props?.data[i]?.destination,
      vibe: props?.data[i]?.vibe,
      status:
        props?.data[i]?.vacation_status == 0 ||
        props?.data[i]?.vacation_status == 1 ||
        props?.data[i]?.vacation_status == false ||
        props?.data[i]?.vacation_status == ""
          ? "Pending"
          : "Approved",
      guestCount: props?.data[i]?.guests_count,
      time: props?.data[i]?.time,
      action: (
        <>
          <Button
            onClick={() => {
              navigate("/vacation/" + props?.data[i]?.id + "/edit", {
                state: { vacation: props?.data[i] },
              });

              localStorage.setItem("vacationdata", JSON.stringify(props?.data[i]));
            }}
            className="btn-sm btn-warning"
          >
            <i className="fa fa-edit"></i> 
          </Button>{" "}
          <Button
            onClick={() => {
              navigate("/vacation/" + props?.data[i]?.id, {
                state: { vacation: props?.data[i] },
              });
            }}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye"></i>
          </Button>
        </>
      ),
    });
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <>
      <Table columns={props?.columns} dataSource={data} />
      <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Contact Us Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Full Name:</strong>
              </div>
              <div className="mx-2 flex2">Test User</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2 flex2">Something@gmail.com</div>
            </div>
            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Subject:</strong>
              </div>
              <div className="mx-2 flex2">Something Something</div>
            </div>

            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Phone Number:</strong>
              </div>
              <div className="mx-2 flex2">+92 301 309 8556</div>
            </div>

            <div className="d-flex">
              <div className="mb-1 flex1">
                <strong>Message:</strong>
              </div>
              <div className="mx-2 flex2">
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, Lorem Ipsum is simply dummy text of
                the printing and typesetting industry.
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Datatable;
