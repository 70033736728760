import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import Profile from "../assets/img/profile.jpg";
import Header from '../component/header';
import Jumbotran from '../component/Jumbotran';
import ProfileCard from '../component/ProfileCard';
import Sidemenu from '../component/sidemenu';
import { Context } from '../context/ContextProvider';
import Footer from './footer';
const Settings = () => {
  const [old_password,setOld_Password]=useState("")
  const [new_password,setNew_Password]=useState("")
  const [confirm_password,setConfirm_Password]=useState("")

  const {updateAdminProfile}=useContext(Context);
  const navigate=useNavigate()
const userdata = JSON.parse(localStorage.getItem('userData'))
  const onSubmitHandler= async (e)=>{
    if(new_password !== confirm_password){
      toast.error("Please enter the same password")
      return false
    }
     let response= await updateAdminProfile({old_password,new_password, user_id:userdata?.data?.id })
     if (response.status === 200){
            navigate("/dashboard",{state:{toastmessage:response?.data?.message}})
    }else{
      toast.error("Something went wrong")
    }
  }
  return (
    <>
    <ToastContainer/>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page='Account Setting' desc="Account setting" />
              <div className="page-inner">
                {/* <h4 className="page-title">Account Setting</h4> */}
                <div className="row">
                  <div className="col-md-8">
                    <div className="card card-with-nav">

                      <div className="card-body">
                        <div className="row mt-4">
                          <div className="col-md-6 mx-auto">
                            <div className="form-group form-group-default">
                              <label>Current Password</label>
                              <input type="password" onChange={(e)=>setOld_Password(e.target.value)} className="form-control" name="old_password" placeholder="Enter current password" />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-6 mx-auto">
                            <div className="form-group form-group-default">
                              <label>New Password</label>
                              <input type="password" onChange={(e)=>setNew_Password(e.target.value)} className="form-control" name="new_password" placeholder="Enter current password" />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-6 mx-auto">
                            <div className="form-group form-group-default">
                              <label>Confirm Password</label>
                              <input type="password" onChange={(e)=>setConfirm_Password(e.target.value)} className="form-control" name="confirm_password" placeholder="Enter current password" />
                            </div>
                          </div>
                        </div>

                        <div className="text-right col-6 mx-auto mt-4 mb-4">
                          <button className="btn btn-warning mx-3">Cancel</button>
                          <button className="btn btn-success" onClick={onSubmitHandler}  >Update</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <ProfileCard />
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>

        </div>
      </div>


    </>
  )
}

export default Settings