import {ucWords} from "../../utils/util";
import Multiselect from 'multiselect-react-dropdown';


export const MultiSelect = ({
   input,
   formik,
   error,
}) => {
  return (
    <div className="col-md-6 mb-6">
      <div className="form-group">
        <label htmlFor="inputGroupSelect01">{input.label}</label>
        <Multiselect
            options={input.options} // Options to display in the dropdown
            selectedValues={input.selectedValue} // Preselected value to persist in dropdown
            onSelect={input.onSelect} // Function will trigger on select event
            onRemove={input.onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
        />
        {/*<select*/}
        {/*  className="custom-select form-control"*/}
        {/*  value={value}*/}
        {/*  onBlur={onBlur}*/}
        {/*  onChange={changeHandler}*/}
        {/*  name={name}*/}
        {/*  id="inputGroupSelect01"*/}
        {/*>*/}
        {/*  <option value="">Select {ucWords(label)}</option>*/}
        {/*  {options.map((option) => (*/}
        {/*    <option key={option._id} selected={option.selected??false} value={option.value}>*/}
        {/*      {option.name}*/}
        {/*    </option>*/}
        {/*  ))}*/}
        {/*</select>*/}
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};
