export const ucWords = (string) =>{
    if (string && string.length)
        return string[0].toUpperCase()+string.slice(1);
    else
        return string??'';
}
export const getDate = (date) =>{
    const dateObj = new Date(date)
    const month = dateObj.getMonth()+1
    return `${dateObj.getDate()}-${(month >= 10)?(month):"0"+(month)}-${dateObj.getFullYear()}`;
}