import React, { useContext, useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../../component/header";
import Sidemenu from "../../component/sidemenu";
import Jumbotran from "../../component/Jumbotran";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ucWords } from "../../utils/util";
import Loader from "../../component/Loader";
import { Context } from "../../context/ContextProvider";

const AllCategories = () => {
  //states
  const [categories, setCategories] = useState([]);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const { getCategories, deleteCategories } = useContext(Context);

  const { state } = useLocation();
  const [tagModal, setTagModal] = useState(false);
  const showTagModal = (data) => {
    console.log({ data });
    setModalData(data);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const navigate = useNavigate();
  const deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const filtered_categories = [...categories];
        console.log(id);
        let array = filtered_categories.filter((item) => {
          return item.id !== id;
        });
        setCategories(array);
        deleteCategories(id)
          .then((res) => {
            swal("Poof! Category has been deleted!", {
              icon: "success",
            });
            setLoading(false);
          })
          .catch((res) => {
            swal("Something went wrong.", {
              icon: "error",
            });
          });
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getCategories().then((response) => {
      setCategories(response.data.data);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    if (state) toast.success(state?.toastmessage);
  }, []);
  const clickHandler = () => {
    navigate("/category/create", { state: { category: {} } });
  };
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                page="Resource Categories"
                desc="Resource Categories"
                user="category"
                addText="Add Category"
                addRoute="/category/create"
              />
              <div className="page-inner">
                {!loading && (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-success float-right my-2"
                          onClick={clickHandler}
                        >
                          Add Category
                        </button>
                        <table className="table table-hover table-head-bg-primary table-sm-responsive mt-2">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Parent</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {categories && categories?.length > 0 ? (
                              <>
                                {categories.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{ucWords(item.name)}</td>
                                      <td>
                                        {item.parent && item.parent !== ""
                                          ? item.parent
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <span
                                          onClick={() =>
                                            navigate(
                                              "/category/" + item.id + "/edit",
                                              { state: { category: item } }
                                            )
                                          }
                                          className="p-2 btn-warning"
                                          style={{
                                            margin: "4px",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </span>

                                        <span
                                          onClick={(e) => deleteUser(item.id)}
                                          className="p-2 btn-danger"
                                          style={{
                                            margin: "4px",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </>
                            ) : (
                              <>
                                <tr className="text-center">
                                  <td colSpan="4">No Category available.</td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
                <Loader loading={loading} />
              </div>
            </div>

            <Footer />
          </div>

          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>User Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Full Name:</strong>
                  </div>
                  <div className="mx-2">{modalData.name}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Email:</strong>
                  </div>
                  <div className="mx-2">{modalData.email}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Role:</strong>
                  </div>
                  <div className="mx-2">Admin</div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AllCategories;
