import React from "react";
import Input from "./Input";

export default function MultiInputs({ input,formik}) {
  return (
    <div className="row col-md-12 mb-3">
        {
            input?.fields.map((input)=>{
               return <Input
                    key={input?.name}
                    name={input?.name}
                    type={input?.type}
                    readonly={input?.readonly ?? false}
                    label={input?.label??''}
                    placedHolder={input?.placedHolder??''}
                    value={formik?.values[input['name']]}
                    defaultValue={input?.value}
                    changeHandler={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    error={formik?.touched[input['name']]&&formik?.errors[input['name']]}
                    // formik={formik}
                />
            })
        }
    </div>
  );
}
