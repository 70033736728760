export const loginUrl = "dashboard/login"
export const getAllWorkSpacesUrl = "dashboard/workspaces"
export const getAllWorkSpaceProjectsUrl = "dashboard/workspace/projects"
export const getProjectDetailsUrl = "dashboard/project/details"
export const updateAdminProfileUrl = "dashboard/profile"
export const getAllAdminsUsers = "dashboard/admin/users"
export const getAllCategories = "dashboard/category"
export const getAddAdminUser = "dashboard/create/user"
export const updateAdminUser = "dashboard/admin-user"

export const getAllGmUsers = "dashboard/gm/users"
export const getAllAppUsers = "dashboard/app/users"
export const addAppUsersUrl = "dashboard/app/users/add"
export const updateAppUsersProfile = "dashboard/app-user"
export const getAllVacationsUrl = "dashboard/vacation"
export const getAllVacationsDashUrl = "dashboard/get-vacations"
export const addComment = "dashboard/comment"
export const deleteUserURL = "dashboard/app/user/delete"