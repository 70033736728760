import React from "react";

export const TextArea = ({
  name,
  changeHandler,
  onBlur,
  label,
  content,
  error,
  value,
  rows,
}) => {
  const [html, setHtml] = React.useState(content);

  var myHTML = content;

  var strippedHtml = myHTML.replace(/<[^>]+>/g, "");


  return (
    <div className="col-md-12 mb-6">
      <div className="form-group">
        <label htmlFor="inputGroupSelect01">{label}</label>
        <textarea
          className="custom-select form-control"
          value={value}
          onBlur={onBlur}
          onChange={changeHandler}
          name={name}
          rows={rows ?? 5}
          id="inputGroupSelect01"
        >
          {strippedHtml}
        </textarea>

        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};
