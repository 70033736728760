import React, { StrictMode, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Header from "../component/header";
import Jumbotran from "../component/Jumbotran";
import ProfileCard from "../component/ProfileCard";
import Sidemenu from "../component/sidemenu";
import Footer from "../pages/footer";
import { FormComponent } from "../component/formElements/FormComponent";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import api from "../api/api";
import { useLocation, useNavigate } from "react-router";
import { Context } from "../context/ContextProvider";
import EditComponent from "./formElements/EditComponent";

const ProfileComp = (props) => {
  const [tagModal, setTagModal] = useState(false);
  const { userData, setUserData, updateAdminProfile } = useContext(Context);
  const { data } = userData;
  const navigate = useNavigate();
  const state = {
    data: {
      initialValues: {
        name: data?.name,
        email: data?.email,
      },
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Username is Required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is Required")
          .min(2, "Too Short!")
          .max(50, "Too Long!"),
      }),
      submitHandler: async (values) => {
        const data = { ...values, user_id: userData?.data?.id, profile_pic: localStorage.getItem('profileImage')};

        console.log({ data });
        // same shape as initial values
        const response = await updateAdminProfile(data);
        
        if (response.status === 200) {
          let userLocalData = { ...userData, data: response?.data?.data };
          const local = window.localStorage.setItem(
            "userData",
            JSON.stringify(userLocalData)
          );
          setUserData(userLocalData);
          navigate("/dashboard", {
            state: { toastmessage: response?.data?.message },
          });
        } else {
          toast.error("Something went wrong");
        }
      },
      inputs: [
        { name: "name", label: "Name", type: "text" },
        { name: "email", label: "Email", type: "email" },
      ],
      submitButtonLabel: "Update",
    },
  };
  return (
    <>
      <EditComponent state={state} />
      {/*
      <ToastContainer />
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                page={props.page ? "Edit Admin" : "Add Admin"}
                desc={props.page ? "Edit Admin" : "Add Admin"}
              />
              <div className="page-inner">
                {/* <h4 className="page-title">{props.page ? 'Edit Admin' : 'Add Admin'}</h4> 
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-with-nav">
                      <div className="card-body">
                        <FormComponent data={state?.data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Add Tag</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-3">
                <label className="mb-2">Add New Tag</label>
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter keyword"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Add Tag
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>*/}
    </>
  );
};

export default ProfileComp;
