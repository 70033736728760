import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Header from "../../component/header";
import SubTaskDatatable from "../../component/SubTaskDatatable";
import Jumbotran from "../../component/Jumbotran";
import Sidemenu from "../../component/sidemenu";
import { Context } from "../../context/ContextProvider";
import { ucWords } from "../../utils/util";
import Loader from "../../component/Loader";

const SingleVacation = () => {
  const [comments, setComments] = useState([]);
  const [disableField, setDisabledField] = useState([]);
  const [newComment, setNewComment] = useState("");
  const { userData, addCommentToVacation } = useContext(Context);

  const [loading, setLoading] = useState(true);
  const {
    state,
    state: { vacation = {} },
  } = useLocation();

  console.log(vacation);

  const columns = [
    {
      title: "#",
      dataIndex: "number",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "User",
      dataIndex: "user",
    },

    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  console.log({ comments });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const addComment = async () => {
    setDisabledField(false);
    let newC = [
      ...comments,
      {
        id: comments.length,
        content: newComment,
        user: userData.data.name,
        vacation: vacation.title,
      },
    ];
    let data = {
      content: newComment,
      vacation_id: vacation.id,
    };
    let response = await addCommentToVacation(data);
    console.log({ response });
    setComments(newC);
    setNewComment("");
    setDisabledField(true);
  };

  useEffect(() => {
    const controller = new AbortController();
    setComments(vacation?.comments);

    return () => {
      controller.abort();
    };
  }, []);

  var myHTML = vacation?.notes;

  var strippedHtml = myHTML.replace(/<[^>]+>/g, "");
  
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel pt-5">
          <div className="container m-auto">
            <Jumbotran page="Vacations" desc="vacations" />
            {!loading ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="card mt-5 d-flex flex-row w-100 mx-4">
                    <div style={{ flex: "0.6" }}>
                      <img
                        src={
                          vacation?.image
                            ? vacation?.image
                            : "https://via.placeholder.com/150"
                        }
                        className="card-img-top w-100"
                        style={{ height: "400px", objectFit: "contain" }}
                        alt="..."
                      />
                    </div>
                    <div style={{ flex: "1" }} className="mx-3 w-100">
                      <div className="card-body">
                        <h2 className="card-title mb-3">
                          <b>{ucWords(vacation.title)}</b>
                          &nbsp;&nbsp;{" "}
                          <h5 style={{ marginLeft: "30px" }}>
                            {vacation.time}
                          </h5>
                        </h2>
                      </div>
                      <ul className="list-group list-group-flush w-100">
                        <li className="list-group-item d-flex">
                          <span>
                            <strong>Destination:</strong>
                          </span>
                          <span className="" style={{ marginLeft: "10px" }}>
                            {vacation.destination}
                          </span>
                        </li>
                        <li className="list-group-item d-flex">
                          <span>
                            <strong>Start Date:</strong>
                          </span>
                          <span className="" style={{ marginLeft: "10px" }}>
                            {vacation.start_date ?? "N/A"}
                          </span>
                        </li>
                        <li className="list-group-item d-flex">
                          <span>
                            <strong>End Date:</strong>
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            {vacation.end_date ?? "N/A"}
                          </span>
                        </li>
                        <li className="list-group-item d-flex">
                          <span>
                            <strong>Guests Count:</strong>
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            {vacation.guests_count ?? "N/A"}
                          </span>
                        </li>
                        <li className="list-group-item d-flex">
                          <span>
                            <strong>Guests:</strong>
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            {vacation?.vacation_guests &&
                              vacation?.vacation_guests?.length > 0 &&
                              vacation?.vacation_guests?.map((item) => {
                                return (
                                  <>
                                    <span>{ucWords(item.role == 'Client'? null: <>{item.name} ,</> )}&nbsp; </span>
                                  </>
                                );
                              })}
                          </span>
                        </li>
                        {/*<li className="list-group-item d-flex">*/}
                        {/*<span>*/}
                        {/*  <strong>Created:</strong>*/}
                        {/*</span>*/}
                        {/*  <span style={{ marginLeft: "10px" }}>{vacation.time}</span>*/}
                        {/*</li>*/}
                        <li className="list-group-item d-flex">
                          <span>
                            <strong>Vibe:</strong>
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            {vacation.vibe ?? "N/A"}
                          </span>
                        </li>
                        <li className="list-group-item d-flex">
                          <span>
                            <strong>Budget:</strong>
                          </span>
                          <span style={{ marginLeft: "10px" }}>
                            {vacation.budget ?? "N/A"}
                          </span>
                        </li>
                        <li className="list-group-item d-block">
                          <div>
                            <strong>Notes</strong>
                          </div>
                          <p className="card-text">{strippedHtml}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="m-4">
                  <div className="card mt-4 p-5 w-100">
                    <SubTaskDatatable
                      columns={columns}
                      data={vacation.sub_tasks}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8 col-lg-6">
                    <div
                      className="card shadow-0 border"
                      style={{ backgroundColor: "#f0f2f5" }}
                    >
                      <div className="card-body p-4">
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            id="addANote"
                            className="form-control"
                            value={newComment}
                            disabled={!disableField}
                            placeholder="Type comment & Press Enter"
                            onKeyPress={(e) =>
                              (e.code === "Enter" ||
                                e.code === "NumpadEnter") &&
                              addComment()
                            }
                            onChange={(e) => setNewComment(e.target.value)}
                          />
                        </div>
                        {comments &&
                          comments.map((c) => (
                            <div key={c?.id} className="card mb-4">
                              <div className="card-body">
                                <p>{c?.content}</p>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-row align-items-center">
                                    <p className="small mb-0 ms-2 badge">
                                      {c?.user}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVacation;
