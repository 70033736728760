import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const Sidebar = () => {
  const para = window.location.pathname;
  const userData = JSON.parse(localStorage.getItem("userData"));


  return (
    <ul className="nav nav-primary">
      {userData.data.role == "admin" && (
        <>
          <li className={para == "/dashboard" ? "nav-item active" : "nav-item"}>
            <Link to={"/dashboard"}>
              <i className="fa fa-home"></i>
              <p>Dashboard</p>
            </Link>
          </li>

          <li
            className={para == "/workspaces" ? "nav-item active" : "nav-item"}
          >
            <Link to={"/workspaces"}>
              <i className="fa fa-file"></i>
              <p>WorkSpaces</p>
            </Link>
          </li>

          <li
            className={para == "/admin/users" ? "nav-item active" : "nav-item"}
          >
            <Link to={"/admin/users"}>
              <i className="fa fa-user"></i>
              <p>Admin Users</p>
            </Link>
          </li>

          <li className={para == "/gm/users" ? "nav-item active" : "nav-item"}>
            <Link to={"/gm/users"}>
              <i className="fa fa-user"></i>
              <p>GM Users</p>
            </Link>
          </li>

          <li className={para == "/app/users" ? "nav-item active" : "nav-item"}>
            <Link to={"/app/users"}>
              <i className="fa fa-user"></i>
              <p>App Users</p>
            </Link>
          </li>
        </>
      )}
      <li className={para == "/vacations" ? "nav-item active" : "nav-item"}>
        <Link to={"/vacations"}>
          <i className="fa fa-plane"></i>
          <p>Vacations</p>
        </Link>
      </li>
      {userData.data.role == "admin" && (
        <>
          <li className={para == "/category" ? "nav-item active" : "nav-item"}>
            <Link to={"/category"}>
              <i className="fa fa-list-alt"></i>
              <p>Resource Categories</p>
            </Link>
          </li>
        </>
      )}

      {/*<li className={para == '/donations' ? "nav-item active" : "nav-item"}>*/}
      {/*  <Link to={'/donations'}>*/}
      {/*    <i className="fa fa-money"></i>*/}
      {/*    <p>Donations</p>*/}
      {/*  </Link>*/}
      {/*</li>*/}
      {/*<li className={para == '/contact-us' ? "nav-item active" : "nav-item"}>*/}
      {/*  <Link to={'/contact-us'}>*/}
      {/*    <i className="fa fa-envelope-o"></i>*/}
      {/*    <p>Contact Us</p>*/}
      {/*  </Link>*/}
      {/*</li>*/}

      {/* <li className="nav-item">
        <a>
          <i className="fa fa-bell"></i>
          <p>Notifications</p>
        </a>
      </li> */}
    </ul>
  );
};

export default Sidebar;
