import { Formik, Form, Field, useFormik, validateYupSchema } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import Input from "./Input";
import { Select } from "./Select";
import { TextArea } from "./TextArea";
import MultiInputs from "./MultiInputs";
import { MultiSelect } from "./MultiSelect";

export const FormComponent = (props) => {
  const { data, loader } = props;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [initVals, setInitVals] = useState({});
  const [file, setFile] = useState(
    userData?.data?.profile_pic ? userData?.data?.profile_pic : ""
  );

  const formik = useFormik({
    initialValues: { ...initVals },
    validationSchema: data.validationSchema,
    onSubmit: data.submitHandler,
    enableReinitialize: true,
  });
  useEffect(() => {
    setInitVals(data.initialValues);
  }, [props]);

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    console.log(base64);
    localStorage.setItem("profileImage", base64);
    setFile(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const vacation = JSON.parse(localStorage.getItem("vacationdata"));

  const [image, setImage] = useState(
    vacation?.image ? vacation?.image : "https://via.placeholder.com/150"
  );

  const handleFileRead1 = async (event) => {
    const file1 = event.target.files[0];
    console.log(event.target.files[0]);
    if (file1.size > 500000) {
      alert("Max.file size exceeed.");
    } else {
      const base64 = await convertBase641(file1);
      const myArray = base64?.split("base64,");

      console.log(myArray[1])
      localStorage.setItem("image", myArray[1]);
      setImage(base64);
    }
  };
  const convertBase641 = (file1) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file1);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mx-auto">
          {window.location.pathname == "/profile" && (
            <div className="col-12 updateProfile mx-auto pb-5">
              <div className="uploadImagesprofile1">
                <div className="position-relative">
                  <div
                    className="mx-auto mt-5"
                    style={{
                      height: "150px",
                      width: "150px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      backgroundColor: "#cdcdcd",
                    }}
                  >
                    {file && (
                      <div className="mx-auto">
                        <img
                          style={{
                            height: "150px",
                            width: "150px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={file}
                        />
                      </div>
                    )}
                    <input
                      style={{
                        opacity: "0",
                        position: "absolute",
                        top: "0",
                        width: "150px",
                      }}
                      className="form-control"
                      type={"file"}
                      name="profile_pic"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={(e) => {
                        handleFileRead(e);
                      }}
                    />
                    {file == "" && (
                      <div
                        className="text-center d-flex align-items-center justify-content-center"
                        style={{ height: "150px" }}
                      >
                        <b>
                          Upload Image <span className="text-danger">*</span>
                        </b>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {data?.inputs.map((input) =>
            input.type !== "select" && input.type !== "textarea" ? (
              input.type == "custom" ? (
                <MultiInputs input={input} formik={formik} />
              ) : input.type == "multiSelect" ? (
                <MultiSelect
                  input={input}
                  formik={formik}
                  error={
                    formik?.touched[input["name"]] &&
                    formik?.errors[input["name"]]
                  }
                />
              ) : (
                <Input
                  key={input?.name}
                  name={input?.name}
                  type={input?.type && input?.type}
                  label={input?.label}
                  value={formik?.values[input["name"]]}
                  changeHandler={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched[input["name"]] &&
                    formik?.errors[input["name"]]
                  }
                  formik={formik}
                />
              )
            ) : input.type === "select" ? (
              input.options.length ? (
                <Select
                  key={input?.name}
                  name={input?.name}
                  label={input?.label}
                  options={input?.options}
                  selected={input?.selected}
                  disabled={input?.disable}
                  changeHandler={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[input["name"]]}
                  error={
                    formik.touched[input["name"]] &&
                    formik.errors[input["name"]]
                  }
                />
              ) : (
                ""
              )
            ) : (
              <TextArea
                key={input?.name}
                name={input?.name}
                rows={input?.rows}
                label={input?.label}
                content={input?.content}
                changeHandler={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[input["name"]]}
                error={
                  formik.touched[input["name"]] && formik.errors[input["name"]]
                }
              />
            )
          )}
        </div>
        {window.location.pathname == `/vacation/${vacation?.id}/edit` && (
          <div className="col-3 mx-auto pb-5">
            <div className="uploadImagesprofile1">
              <div className="position-relative">
                <div
                  className="mx-auto mt-5"
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    backgroundColor: "#cdcdcd",
                  }}
                >
                  {image && (
                    <div className="mx-auto">
                      <img
                        style={{
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={image}
                      />
                    </div>
                  )}
                  <input
                    className="form-control"
                    id="image-Control"
                    style={{
                      opacity: "0",
                      position: "absolute",
                      top: "0",
                      width: "150px",
                      width: "100%",
                    }}
                    type={"file"}
                    name="image"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => {
                      handleFileRead1(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <button
          // disabled={!formik.dirty || !formik.isValid}
          type="submit"
          className="btn btn-primary pull-right"
        >
          {data.submitButtonLabel}
        </button>
        {data.addField && data.addField.label && (
          <>
            <button
              type="button"
              onClick={() => data.addField.clickHandler(formik)}
              className="btn btn-primary pull-left"
            >
              {data.addField.label}
            </button>
          </>
        )}
      </form>
    </>
  );
};
