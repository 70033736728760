import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Header from "../../component/header";
import SubTaskDatatable from "../../component/SubTaskDatatable";
import Jumbotran from "../../component/Jumbotran";
import Sidemenu from "../../component/sidemenu";
import { Context } from "../../context/ContextProvider";
import { ucWords } from "../../utils/util";
import { Link } from "react-router-dom";

const SingleVacationCategory = () => {
  const [comments, setComments] = useState([]);
  const {
    state,
    state: { vacation = {} },
  } = useLocation();

  const columns = [
    {
      title: "#",
      dataIndex: "number",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "User",
      dataIndex: "user",
    },
    {
      title: "Assigned To",
      dataIndex: "admin",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  console.log(vacation);
  useEffect(() => {
    const controller = new AbortController();
    setComments(vacation?.comments);

    return () => {
      controller.abort();
    };
  }, []);
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel pt-5">
          <div className="container m-auto">
            <Jumbotran page="Reguested Resource" desc="Reguested Resource" />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="card mt-4 col-8 mx-aut">
                <div style={{ flex: "1" }} className="w-100 p-5">
                  <div className="card-body p-0 m-0">
                    <h2 className=" mb-5" style={{ fontSize: "32px" }}>
                      <b>{ucWords(vacation.title)}</b>
                    </h2>
                  </div>

                  {vacation?.resource_fields?.map((item, index) => {
                    return (
                      <>
                        <div className="d-flex my-3">
                          <div style={{ flex: ".6" }}>
                            <strong>{item?.value?.label}:</strong>
                          </div>
                          <div
                            className=""
                            style={{ marginLeft: "10px", flex: "2" }}
                          >
                            {item?.value?.value}
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}

                  {vacation?.resource_images.SingleImage && (
                    <>
                      <div className="d-flex my-3">
                        <div style={{ flex: ".6" }}>
                          <strong>
                            {vacation?.resource_images?.SingleImage?.label}:
                          </strong>
                        </div>
                        <div style={{ marginRight: "10px", flex: "2" }}>
                          <img
                            src={vacation?.resource_images?.SingleImage?.url}
                            className="card-img-top"
                            style={{
                              height: "150px",
                              marginRight: "12px",
                              width: "200px",
                              objectFit: "cover",
                            }}
                            alt="..."
                          />
                        </div>
                      </div>
                      <hr />
                    </>
                  )}

                  {vacation?.resource_images.dynamicImages && (
                    <>
                      <div className="d-flex my-3">
                        <div style={{ flex: ".6" }}>
                          <strong>
                            {vacation?.resource_images?.dynamicImages?.label}:
                          </strong>
                        </div>
                        <div style={{ marginRight: "10px", flex: "2" }}>
                          {vacation?.resource_images?.dynamicImages?.images?.map(
                            (url, index) => {
                              return (
                                <img
                                  src={url}
                                  className="card-img-top "
                                  style={{
                                    height: "150px",
                                    width: "200px",
                                    marginRight: "12px",
                                    objectFit: "cover",
                                  }}
                                  alt="..."
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              </div>

             
            </div>

            <div className="col-md-8 mx-auto mt-2  pb-5 mb-5">
                <Link to={`/vacations`}>
                back
                </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVacationCategory;
