import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import AddComponent from "../../component/formElements/AddComponent";
import { Context } from "../../context/ContextProvider";


const AddUser = (props) => {
  const navigate = useNavigate()
  const {addAppUsers}=useContext(Context);

  const [loader, setLoader]= useState(false)

  const state = {
    data: {
      initialValues: {
        name: "",
        email: "",
        password: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Username is Required"),
        password: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Password is Required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is Required")
          .min(2, "Too Short!")
          .max(50, "Too Long!")
      }),
      submitHandler: async (values) => {
        const data = {...values}
        setLoader(true)
        // same shape as initial values
        const response = await addAppUsers(data)
        if (response.status === 201){
          setLoader(false)
                navigate("/app/users",{state:{toastmessage:response.data.message}})
        }else{
          setLoader(false)
          toast.error("Something went wrong")
        }
      },
      inputs: [
        { name: "name", label: "Name",type:"text" },
        { name: "email", label: "Email",type:"email" },
        { name: "password", label: "Password",type:"password" },
      ],
      submitButtonLabel: "Add User",
    },
  };
  return (
    <>
    <AddComponent state={state} page={"Add User"} levels={[{text:"App Users",link:"/app/users"}]} loader={loader} />
    </>
    );
};

export default AddUser;
