import React from "react";
import { Link } from "react-router-dom";

const ProfileCard = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return (
    <>
      <div className="card card-profile">
        <div
          className="card-header"
          style={{
            backgroundImage: `url(http://demo.themekita.com/atlantis/livepreview/examples/assets/img/blogpost.jpg)`,
          }}
        >
          <div className="profile-picture">
            <div className="avatar avatar-xl">
              <img
                src={
                  userData?.data?.profile_pic
                    ? userData?.data?.profile_pic
                    : "http://demo.themekita.com/atlantis/livepreview/examples/assets/img/profile.jpg"
                }
                alt="..."
                className="avatar-img rounded-circle"
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="user-profile text-center">
            <div className="name">{userData?.data?.name}</div>
            <div className="desc">{userData?.data?.email}</div>

            <div className="view-profile">
              <Link to={"/profile"} className="btn btn-secondary btn-block">
                View Full Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
