import React from "react";
import { Link } from "react-router-dom";
import {ucWords} from "../utils/util";


const Jumbotran = (props) => {
  const {levels} = props
  return (
    <div className="panel-header bg-primary-gradient">
      <div className="page-inner py-5">
        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
          <div>
            <h2 className="text-white pb-2 fw-bold">{props.page}</h2>
            <h5 className="text-white op-7 mb-2" style={{ color: '#fff !important', opacity: '1' }}>
              <Link className="text-decoration-none" to={'/dashboard'}> Dashboard </Link>
              {
                levels && levels.map((item)=>(
                      <>
                        <Link className="text-decoration-none" to={ucWords(item.link)}> <i className="fa fa-angle-right "></i> {ucWords(item.text)} </Link>
                      </>
                  ))
              }
              {props.desc && <i className="fa fa-angle-right "></i>} <span style={{ opacity: '.57' }} >{props.desc}</span>
            </h5>
          </div>

          {/*{props.user ? */}
          {/*    <div className="ml-md-auto py-2 py-md-0">*/}
          {/*      {*/}
          {/*        props.addText && props.addRoute &&*/}
          {/*      */}
          {/*        <Link to={props.addRoute} className="btn btn-secondary btn-round">*/}
          {/*        {props.addText}*/}
          {/*      </Link>*/}
          {/*      }*/}
          {/*    </div> */}
          {/*: <div className="ml-md-auto py-2 py-md-0">*/}
          {/*  <Link to={'/case'} className="btn btn-white btn-border btn-round mr-2">*/}
          {/*    Manage Cases*/}
          {/*  </Link>*/}
          {/*  <Link to="/add-cause" className="btn btn-secondary btn-round">*/}
          {/*    Add Cases*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/*}*/}

        </div>
      </div>
    </div>

  );
};

export default Jumbotran;
