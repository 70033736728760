import React, { useContext, useState } from "react";
import Footer from "../../footer";
import Header from "../../../component/header";
import Sidemenu from "../../../component/sidemenu";
import Jumbotran from "../../../component/Jumbotran";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import { Context } from "../../../context/ContextProvider";
import { getDate, ucWords } from "../../../utils/util";

const Projects = () => {
  const {
    state: { projects },
  } = useLocation();
  const [projectDetails, setProjectDetails] = useState({});
  const [members, setMembers] = useState({});
  const { getProjectDetailsHandler } = useContext(Context);
  const navigate = useNavigate();
  const deleteUser = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your user has been deleted!", {
          icon: "success",
        });
      }
    });
  };
  const gotoTransaction = (e) => {
    navigate("/transaction");
  };

  const [tagModal, setTagModal] = useState(false);
  const showTagModal = (project) => {
    getProjectDetailsHandler(project).then((response) => {
      setProjectDetails(response.project);
      setMembers(response.members);
      setTagModal(true);
    });
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const handleAddUser = (member) => {
    navigate("/admin/users/add", { state: { member } });
  };

  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                page="Projects"
                levels={[
                  {
                    text: "WorkSpaces",
                    link: "/workspaces",
                  },
                ]}
                desc="Projects List"
              />
              <div className="page-inner">
                {/* <h4 className="page-title">WorkSpace</h4> */}
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-hover table-head-bg-primary table-sm-responsive mt-2">
                      <thead>
                        <tr>
                          <th colSpan={"1"}>#</th>
                          <th colSpan={"1"}>GID</th>
                          <th colSpan={"1"}>Name</th>
                          <th colSpan={"1"}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects && projects?.length > 0 ? (
                          <>
                            {projects.map((item, index) => (
                              <React.Fragment key={item.gid}>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.gid}</td>
                                  <td>{item.name}</td>

                                  <td>
                                    <span
                                      className="p-2 btn-info"
                                      onClick={() => showTagModal(item)}
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </span>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr className="text-center">
                              <td colSpan="5">No projects available.</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>

          {projectDetails && Object.keys(projectDetails).length && (
            <>
              <Modal show={tagModal} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title>
                    Project Details{" "}
                    <span
                      className={`badge ${
                        projectDetails.completed
                          ? "badge-success"
                          : "badge-danger"
                      }`}
                    >
                      {projectDetails.completed ? "Completed" : "Not Completed"}
                    </span>
                  </Modal.Title>
                  <button onClick={handleClose} className="border-0">
                    X
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="p-2">
                    <div className="d-flex">
                      <div className="mb-1">
                        <strong>Project WorkSpace:</strong>
                      </div>
                      <div className="mx-2">
                        {ucWords(projectDetails?.workspace?.name)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mb-1">
                        <strong>Project Owner:</strong>
                      </div>
                      <div
                        className="mx-2"
                        style={{ textDecoration: "underline" }}
                      >
                        {ucWords(projectDetails.owner.name)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mb-1">
                        <strong>Project Name:</strong>
                      </div>
                      <div className="mx-2">{ucWords(projectDetails.name)}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mb-1">
                        <strong>Project Start Date:</strong>
                      </div>
                      <div className="mx-2">
                        {getDate(projectDetails.created_at)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mb-1">
                        <strong>Project Current Status:</strong>
                      </div>
                      <div className="mx-2">
                        {projectDetails.current_status
                          ? projectDetails.current_status
                          : "Not Assigned"}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mb-1">
                        <strong>Project Custom Fields:</strong>
                      </div>
                      <div
                        className="mx-2 mb-3"
                        style={{ maxHeight: "300px", overflowY: "auto" }}
                      >
                        {projectDetails.custom_field_settings &&
                          projectDetails.custom_field_settings.map(
                            (item, index) => (
                              <>
                                &nbsp;{" "}
                                <span
                                  key={item.gid}
                                  className={
                                    "badge badge-info font-weight-bold"
                                  }
                                >
                                  {item.custom_field.name}
                                </span>
                              </>
                            )
                          )}
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="mb-1">
                        <strong>Project Members:</strong>
                      </div>
                      <div
                        className="mx-2 mb-3"
                        style={{ maxHeight: "300px", overflowY: "auto" }}
                      >
                        {members &&
                          Object.keys(members).length &&
                          members.map((item, index) => (
                            <>
                              &nbsp;
                              <span
                                style={{ cursor: "pointer" }}
                                key={item.gid}
                                onClick={() =>
                                  !item.is_system_user && handleAddUser(item)
                                }
                                className={`badge font-weight-bold ${
                                  item.is_system_user
                                    ? "badge-success"
                                    : "badge-warning"
                                }`}
                              >
                                {item.name}
                                {item.is_system_user ? (
                                  <>
                                    <span>
                                      <i className={"fa fa-check"}></i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    &nbsp;{" "}
                                    <span>
                                      <i className={"fa fa-plus"}></i>
                                    </span>
                                  </>
                                )}
                              </span>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Projects;
