import React, { useContext, useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../../component/header";
import Sidemenu from "../../component/sidemenu";
import Jumbotran from "../../component/Jumbotran";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import { Button, Modal, ToastContainer } from "react-bootstrap";
import { Context } from "../../context/ContextProvider";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";
import { deleteUserURL } from "../../api/constants";

const Users = () => {
  const [tagModal, setTagModal] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [user, setUser] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getAppUsers, deleteRecord } = useContext(Context);
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      console.log({ state });
      toast.success(state.toastmessage);
    }
  }, []);

  const showTagModal = (data) => {
    console.log({ data });
    setModalData(data);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const navigate = useNavigate();

  const deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteRecord(id, deleteUserURL)
          .then((res) => {
            if (res.status == 200) {
              swal(res.data.message, {
                icon: "success",
              });
              setLoading(false);
              console.log({ res });
              getAppUser();
            }
            if (res.status == 400) {
              swal(res.data.message, {
                icon: "warning",
              });
              setLoading(false);
            }
          })
          .catch((res) => {
            swal("Something went wrong.", {
              icon: "error",
            });
          });
        setLoading(false);
      }
    });
  };

  const getAppUser = async () => {
    setLoading1(true);
    let response = await getAppUsers();
    setUser(response?.data?.data);
    setLoading1(false);
  };
  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        setLoading(true);
        getAppUser();
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const clickHandler = () => {
    navigate("/add-user");
  };
  return (
    <>
      <ToastContainer />
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="App Users" desc="App Users" user="app/users" />
              <div className="page-inner">
                {/* <h4 className="page-title">User</h4> */}
                {!loading1 ? (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-success float-right my-2"
                          onClick={clickHandler}
                        >
                          Add Guest User
                        </button>
                        <table className="table table-hover table-head-bg-primary table-sm-responsive mt-2">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Full Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Role</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user && user?.length > 0 ? (
                              <>
                                {user.map((u, i) => (
                                  <tr key={u.id}>
                                    <td>{i + 1}</td>
                                    <td>{u.name}</td>
                                    <td>{u.email}</td>
                                    <td>{u.role ?? "N/A"}</td>
                                    <td>
                                      <span
                                        onClick={() =>
                                          navigate("/app/user/edit-user", {
                                            state: { user: u },
                                          })
                                        }
                                        className="p-2 btn-warning"
                                        style={{
                                          margin: "4px",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </span>
                                      <span
                                        className="p-2 btn-info"
                                        onClick={() => showTagModal(u)}
                                        style={{
                                          margin: "4px",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </span>
                                      <span
                                        onClick={(e) => deleteUser(u.id)}
                                        className="p-2 btn-danger"
                                        style={{
                                          margin: "4px",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <>
                                <tr className="text-center">
                                  <td colSpan="5">No user available.</td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>

            <Footer />
          </div>

          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>User Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Full Name:</strong>
                  </div>
                  <div className="mx-2">{modalData.name}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Email:</strong>
                  </div>
                  <div className="mx-2">{modalData.email}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Role:</strong>
                  </div>
                  <div className="mx-2">User</div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Users;
