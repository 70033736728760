import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Context } from "../../context/ContextProvider";
import EditComponent from "../../component/formElements/EditComponent";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Loader from "../../component/Loader";

const EditVacation = () => {
  const {
    state,
    state: { vacation = {} },
  } = useLocation();

  const [loading, setLoading] = useState(false);
  const [guests, setGuests] = useState([]);
  const [selectGuests, setSelectGuests] = useState([]);

  const [users, setUsers] = useState([]);
  const [appGuests, setAppGuests] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [categories, setCategories] = useState([]);
  const { getAppUsers, getGuests, getAdmins, updateVacation, getCategories } =
    useContext(Context);

  const navigate = useNavigate();
  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    if (vacation.parent_id) {
      getCategories().then((res) => {
        setCategories([...res.data.data]);
        setLoading(false);
      });
    }
    getAppUsers().then((res) => {
      setUsers([...res.data.data]);
      localStorage.setItem("users", JSON.stringify([...res.data.data]));
    });
    getGuests().then((res) => {
      console.log({ res });
      setAppGuests([...res.data.data]);
    });
    getAdmins().then((res) => {
      setAdmins([...res.data.data]);
      setLoading(false);
    });
    vacation.vacation_guests &&
      vacation.vacation_guests?.length > 0 &&
      vacation.vacation_guests?.map((item) => {
        setSelectGuests([...selectGuests, item]);
      });
    // console.log({ selectGuests });
    return () => {
      controller.abort();
    };
  }, []);

  const imageLocal = localStorage.getItem("image");
  const [hasImage, setHasImage] = useState(false);
  useEffect(() => {
    if (imageLocal) {
      setHasImage(true);
    }
  }, []);
  let vacationStatus = [
    ,
    {
      id: "1",
      name: "Pending",
      value: "1",
      selected: true,
    },
    {
      id: "2",
      name: "Approved",
      value: "2",
      selected: "",
    },
  ];
  const stateEdit = {
    data: {
      initialValues: {
        title: vacation.title,
        destination: vacation.destination,
        vibe: vacation.vibe,
        vacation_status: vacation.vacation_status,
        guests_count: vacation.guests_count,
        budget: vacation.budget,
        start_date: vacation.start_date,
        end_date: vacation.end_date,
      },
      validationSchema: Yup.object().shape({
        title: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
        start_date: Yup.date().required(),
        end_date: Yup.date().required(),
      }),
      submitHandler: async (values) => {
        const data = {
          ...values,
        };
        if (localStorage.getItem("image")) {
          data.image = localStorage.getItem("image");
        }

        data["guests"] = [];
        guests &&
          guests.map((guest, key) => {
            data["guests"][key] = guest.id ? guest.id : guest._id;
          });

        console.log(data);
        // same shape as initial values
        const response = await updateVacation(vacation.id, data);
        if (response.status === 200) {
          navigate("/vacations", {
            state: { toastmessage: response.data.message },
          });
        } else {
          toast.error("Something went wrong");
        }
      },
      inputs: [
        { name: "title", label: "Title", type: "text" },
        { name: "destination", label: "Destination", type: "text" },
        { name: "vibe", label: "Vibe", type: "text" },

        {
          name: "vacation_status",
          label: "Status",
          type: "select",
          options:
            vacationStatus &&
            vacationStatus.map((user, key) => ({
              _id: user.id,
              name: user.name,
              value: user.id,
              selected: vacation.user_id === user.id,
            })),
        },

        { name: "budget", label: "Budget", type: "number" },
        { name: "start_date", label: "Start Date", type: "date" },
        { name: "end_date", label: "End Date", type: "date" },
        {
          name: "user_id",
          label: "Vacation User",
          type: "select",
          options:
            users &&
            users.map((user, key) => ({
              _id: user.id,
              name: user.name,
              value: user.id,
              selected: vacation.user_id === user.id,
            })),
        },
        {
          name: "admin_id",
          label: "Admin / GM",
          type: "select",
          options:
            admins &&
            admins.map((admin, key) => ({
              _id: admin.id,
              name: admin.name,
              value: admin.id,
              selected: vacation.admin_id == admin.id,
            })),
        },
        {
          name: "category_id",
          label: "Resource Category",
          type: "select",
          options:
            categories &&
            categories.map((admin, key) => ({
              _id: admin.id,
              name: admin.name,
              value: admin.id,
              selected: vacation.category_id == admin.id,
            })),
        },
        {
          name: "guests",
          label: "Guests",
          type: "multiSelect",
          selectedValue: selectGuests ?? [],
          options:
            appGuests &&
            appGuests.map((user, key) => ({
              _id: user.id,
              name: user.name,
              value: user.id,
            })),
          onSelect: (data) => {
            setGuests(data);
            console.log({ guests, data });
          },
        },
        {
          name: "notes",
          rows: 3,
          label: "Notes",
          type: "textarea",
          content: vacation.notes,
        },
      ],
      submitButtonLabel: "Update",
    },
    loading: loading,
  };

  return (
    <>
      <ToastContainer />
      <EditComponent
        page={"Edit Vacation"}
        desc={"Edit Vacation"}
        level={[{ text: "Vacations", link: "/vacations" }]}
        state={stateEdit}
      />
    </>
  );
};

export default EditVacation;
