import React, {useContext, useState} from "react";
import { useLocation, useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";

import EditComponent from "../../component/formElements/EditComponent";
import { Context } from "../../context/ContextProvider";

const EditAdmin = (props) => {
  const { EditAdminUser } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    state: { user},
  } = useLocation();
  const stateEdit = {
    data: {
      initialValues: {
        name: user.name,
        email: user.email,
      },
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Username is Required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is Required")
          .min(2, "Too Short!")
          .max(50, "Too Long!"),
      }),
      submitHandler: async (values) => {
        const data = { ...values, user_id: user.id };
        // same shape as initial values
        const response = await EditAdminUser(data);
        if (response.status === 200) {
          navigate("/admin/users", {
            state: { toastmessage: response.data.message },
          });
        } else {
          toast.error("Something went wrong");
        }
      },
      inputs: [
        { name: "name", label: "Name", type: "text" },
        { name: "email", label: "Email", type: "email" },
      ],
      submitButtonLabel: "Update",
    },
    loading:loading
  };
  return (
    <>
      <ToastContainer />
      <EditComponent
        page={user.role === "gm" ? "Edit GM" : "Edit Admin"}
        desc={user.role === "gm" ? "Edit GM" : "Edit Admin"}
        level={
          user.role === "gm"
            ? [{ text: "GM User", link: "/gm/users" }]
            : [{ text: "Admin User", link: "/admin/users" }]
        }
        state={stateEdit}
      />
    </>
  );
};

export default EditAdmin;
