import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {Context, ContextProvider} from '../context/ContextProvider';

const NotFound = () => {
  const {userData} = useContext(Context);
  return (
    <div className="page-not-found">
      <div className="wrapper not-found">
        <h1 className="animated fadeIn">404</h1>
        <div className="desc animated fadeIn"><span>OOPS!</span><br />Looks like you get lost</div>
        <Link to={userData && Object.keys(userData).length?'/dashboard':"/"} className="btn btn-primary btn-back-home mt-4 animated fadeInUp">
          <span className="btn-label mr-2">
            <i className="flaticon-home"></i>
          </span>
          Back To {userData && Object.keys(userData).length?'Dashboard':"Login"}
        </Link>
      </div>
    </div>
  )
}

export default NotFound