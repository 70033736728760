import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import api from "../api/api";
import {
  addAppUsersUrl,
  addComment,
  deleteUser,
  deleteUserURL,
  getAddAdminUser,
  getAllAdminsUsers,
  getAllAppUsers,
  getAllCategories,
  getAllVacationsDashUrl,
  getAllVacationsUrl,
  getAllWorkSpaceProjectsUrl,
  getAllWorkSpacesUrl,
  getProjectDetailsUrl,
  loginUrl,
  updateAdminProfileUrl,
  updateAdminUser,
  updateAppUsersProfile,
} from "../api/constants";
import { toast } from "react-toastify";

export const Context = React.createContext();

export const ContextProvider = (props) => {
  const { children } = props;
  const [userData, setUserData] = useState({});
  const [loading, setloading] = useState(false);
  const checkUserAuth = async () => {
    const userData = localStorage.getItem("userData");
    setUserData(JSON.parse(userData));
    if (!userData) {
      console.log("checkUserAuth");
      return <Navigate to="/" />;
    }
  };

  const redirectUser = (path, navigate) => {
    return navigate(path);
  };

  useEffect(() => {
    checkUserAuth();
  }, []);

  {
    /**********************Login Method start**********************/
  }
  const loginHandler = async (data, navigation) => {
    setloading(true);
    api
      .post(`${process.env.REACT_APP_BASE_URL}${loginUrl}`, data)
      .then((response) => {
        if (response.status !== 200) {
          setloading(false);
          toast.error(response.data.message);
        } else {
          try {
            localStorage.setItem("userData", JSON.stringify(response.data));
            localStorage.setItem("token", JSON.stringify(response.data.token));
            toast.success(response.data.message);
            setUserData(response.data);
            setloading(false);
            navigation("/dashboard", {
              state: { toastMessage: response.data.message },
            });
          } catch (error) {
            console.log({ error });
          }
        }
      });
  };

  {
    /**********************Login Method end**********************/
  }

  {
    /**********************WorkSpaces Method start**********************/
  }

  const getWorkSpacesHandler = () => {
    return api
      .authGet(`${process.env.REACT_APP_BASE_URL}${getAllWorkSpacesUrl}`)
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          return response.data;
        }
      });
  };
  {
    /**********************WorkSpaces Method End**********************/
  }
  {
    /**********************WorkSpaceProject Method start**********************/
  }
  const getWorkSpaceProjectsHandler = (workspace, navigate) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${getAllWorkSpaceProjectsUrl}`,
        { workspace_gid: workspace.gid }
      )
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          navigate("/workspace/projects", {
            state: { projects: response.data },
          });
        }
      });
  };
  {
    /**********************WorkSpaceProject Method End**********************/
  }

  {
    /**********************ProjectDetails Method start**********************/
  }

  const getProjectDetailsHandler = (project) => {
    return api
      .authPost(`${process.env.REACT_APP_BASE_URL}${getProjectDetailsUrl}`, {
        project_gid: project.gid,
      })
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          return response.data;
        }
      });
  };
  {
    /**********************ProjectDetails Method End**********************/
  }
  {
    /**********************AddAdminUser Method start**********************/
  }

  const getAddAdminUserHandler = (data) => {
    return api
      .authPost(`${process.env.REACT_APP_BASE_URL}${getAddAdminUser}`, {
        ...data,
      })
      .then((response) => {
        if (response.status !== 201) toast.error(response.data.message);
        else {
          return response;
        }
      });
  };
  {
    /**********************AddAdminUser Method End**********************/
  }

  {
    /********************** deleteAdminUser Method start**********************/
  }

  const deleteAdminUser = (id) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${getAllAdminsUsers}` + "/" + id,
        {},
        "DELETE"
      )
      .then((response) => {
        if (response.status !== 200) return response;
        else {
          return response;
        }
      });
  };
  {
    /********************** deleteAdminUser Method End**********************/
  }

  const deleteUserProfile = (id) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${deleteUserURL}` + "/" + id,
        {},
        "DELETE"
      )
      .then((response) => {
        if (response.status !== 200) return response;
        else {
          return response;
        }
      });
  };

  {
    /********************** EditAdminUser Method start**********************/
  }

  const EditAdminUser = (data) => {
    return api
      .authPost(`${process.env.REACT_APP_BASE_URL}${updateAdminUser}`, {
        ...data,
      })
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          return response;
        }
      });
  };
  {
    /********************** EditAdminUser Method End**********************/
  }

  {
    /********************** updateVacation Method start**********************/
  }

  const updateVacation = (id, data) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${getAllVacationsUrl}` + "/" + id,
        {
          ...data,
        },
        "PUT"
      )
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          return response;
        }
      });
  };
  {
    /********************** updateVacation Method End**********************/
  }

  {
    /********************** deleteVacation Method start**********************/
  }

  const deleteVacation = (id) => {
    const response = api.authPost(
      `${process.env.REACT_APP_BASE_URL}${getAllVacationsUrl}` + "/" + id,
      {},
      "DELETE"
    );
    return response;
  };
  {
    /********************** deleteVacation Method End**********************/
  }

  {
    /********************** deleteRecord Method start Single method to delete multiple records types based on params **********************/
  }

  const deleteRecord = (id, uri) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${uri}` + "/" + id,
        {},
        "DELETE"
      )
      .then((response) => {
        if (response.status !== 200) return response;
        else {
          return response;
        }
      });
  };
  {
    /********************** deleteRecord Method End  Single method to delete multiple records types based on params **********************/
  }

  {
    /**********************GetAppUsers Method start**********************/
  }
  const getAppUsers = async () => {
    try {
      let response = await api.authGet(
        `${process.env.REACT_APP_BASE_URL}${getAllAppUsers}`
      );

      if (response.status !== 200) toast.error(response.data.message);
      else {
        console.log(response.data);
        // setGms(response.data.data);
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  };
  {
    /**********************GetAppUsers Method End**********************/
  }

  {
    /**********************getGuests Method start**********************/
  }
  const getGuests = async () => {
    try {
      let response = await api.authGet(
        `${process.env.REACT_APP_BASE_URL}${getAllAppUsers}?role=guest`
      );
      if (response.status !== 200) toast.error(response.data.message);
      else {
        console.log(response.data);
        // setGms(response.data.data);
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  };
  {
    /**********************getGuests Method End**********************/
  }

  {
    /**********************AddAppUsers Method start**********************/
  }
  const addAppUsers = async (data) => {
    try {
      let response = await api.authPost(
        `${process.env.REACT_APP_BASE_URL}${addAppUsersUrl}`,
        { ...data }
      );
      if (response.status !== 201) toast.error(response.data.message);
      else {
        // setGms(response.data.data);
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  };
  {
    /**********************AddAppUsers Method End**********************/
  }
  {
    /**********************Edit Admin Start**********************/
  }
  const updateAdminProfile = async (data) => {
    console.log({ data });
    try {
      let response = await api.authPost(
        `${process.env.REACT_APP_BASE_URL}${updateAdminProfileUrl}`,
        {
          ...data,
        }
      );
      if (response.status !== 200) toast.error(response.data.message);
      else {
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  };
  {
    /**********************Edit Admin End**********************/
  }
  {
    /**********************Edit User Start**********************/
  }

  const updateUserProfile = async (data) => {
    console.log({ data });
    try {
      let response = await api.authPost(
        `${process.env.REACT_APP_BASE_URL}${updateAppUsersProfile}`,
        {
          ...data,
        }
      );
      if (response.status !== 200) toast.error(response.data.message);
      else {
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  {
    /**********************Edit User End**********************/
  }

  {
    /**********************GetAllLocations Method start**********************/
  }
  const getAllVacations = async () => {
    try {
      let response = await api.authGet(
        `${process.env.REACT_APP_BASE_URL}${getAllVacationsUrl}`
      );
      console.log(response);

      if (response.status !== 201) toast.error(response.data.message);
      else {
        console.log(response.data);
        // setGms(response.data.data);
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  };
  {
    /**********************GetAllVacations Method End**********************/
  }

  {
    /********************** EditVacation Method start**********************/
  }

  const EditVacations = (data) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${getAllVacationsUrl}`,
        {
          ...data,
        },
        "PUT"
      )
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          return response;
        }
      });
  };
  {
    /********************** EditVacation Method End**********************/
  }

  {
    /********************** getAdmins Method start**********************/
  }

  const getAdmins = (data) => {
    return api
      .authGet(`${process.env.REACT_APP_BASE_URL}${getAllAdminsUsers}`, {
        ...data,
      })
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          return response;
        }
      });
  };
  {
    /********************** getAdmins Method End**********************/
  }

  {
    /********************** getCategories Method start**********************/
  }

  const getCategories = (data) => {
    return api
      .authGet(`${process.env.REACT_APP_BASE_URL}${getAllCategories}`, {
        ...data,
      })
      .then((response) => {
        if (response.status !== 200) toast.error(response.data.message);
        else {
          return response;
        }
      });
  };
  {
    /********************** getCategories Method End**********************/
  }

  {
    /********************** addCategories Method start**********************/
  }

  const addCategories = (data) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${getAllCategories}`,
        {
          ...data,
        },
        "POST"
      )
      .then((response) => {
        if (response.status !== 200) return response;
        else {
          return response;
        }
      });
  };
  {
    /********************** addCategories Method End**********************/
  }

  {
    /********************** updateCategories Method start**********************/
  }

  const updateCategories = (id, data) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${getAllCategories}` + "/" + id,
        {
          ...data,
        },
        "PUT"
      )
      .then((response) => {
        if (response.status !== 200) return response;
        else {
          return response;
        }
      });
  };
  {
    /********************** updateCategories Method End**********************/
  }

  {
    /********************** deleteCategories Method start**********************/
  }

  const deleteCategories = (id) => {
    return api
      .authPost(
        `${process.env.REACT_APP_BASE_URL}${getAllCategories}` + "/" + id,
        {},
        "DELETE"
      )
      .then((response) => {
        if (response.status !== 200) return response;
        else {
          return response;
        }
      });
  };

  {
    /********************** deleteCategories Method End**********************/
  }

  {
    /**********************Add Comments Method End**********************/
  }
  const addCommentToVacation = async (data) => {
    try {
      let response = await api.authPost(
        `${process.env.REACT_APP_BASE_URL}${addComment}`,
        {
          ...data,
        }
      );
      if (response.status !== 200) toast.error(response.data.message);
      else {
        return response;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  {
    /**********************Add Comments Method End**********************/
  }

  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        loginHandler,
        getWorkSpacesHandler,
        getWorkSpaceProjectsHandler,
        getProjectDetailsHandler,
        getAddAdminUserHandler,
        getAppUsers,
        updateAdminProfile,
        updateUserProfile,
        addAppUsers,
        EditAdminUser,
        getAllVacations,
        addCommentToVacation,
        getAdmins,
        updateVacation,
        deleteVacation,
        getCategories,
        addCategories,
        updateCategories,
        deleteCategories,
        deleteAdminUser,
        deleteRecord,
        getGuests,
        loading,
        deleteUserProfile,
      }}
    >
      {children}
    </Context.Provider>
  );
};

// export default Context.Provider
