import React, { StrictMode, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Header from "../../component/header";
import Jumbotran from "../../component/Jumbotran";
import ProfileCard from "../../component/ProfileCard";
import Sidemenu from "../../component/sidemenu";
import Footer from "../footer";
import { FormComponent } from "../../component/formElements/FormComponent";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import api from "../../api/api";
import { useLocation, useNavigate } from "react-router";
import { Context } from "../../context/ContextProvider";
import AddComponent from "../../component/formElements/AddComponent";

const AddAdmin = (props) => {
  const [tagModal, setTagModal] = useState(false);
  const {
    state: { member },
  } = useLocation();
  const { getAddAdminUserHandler } = useContext(Context);
  const navigate = useNavigate();
  const state = {
    data: {
      initialValues: {
        name: member.name ?? "",
        email: member.email ?? "",
        password: "",
        user_role: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Username is Required"),
        password: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Password is Required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is Required")
          .min(2, "Too Short!")
          .max(50, "Too Long!"),
        user_role: Yup.string().required("User Role is Required"),
      }),
      submitHandler: async (values) => {
        const data = { ...values, gid: member.gid };
        // same shape as initial values
        const response = await getAddAdminUserHandler(data);
        if (response.status === 201) {
          if (response.data.data.roles[0].name === "gm")
            navigate("/gm/users", {
              state: { toastmessage: response.data.message },
            });
          else
            navigate("/admin/users", {
              state: { toastmessage: response.data.message },
            });
        } else {
          toast.error("Something went wrong");
        }
      },
      inputs: [
        { name: "name", label: "Name", type: "text" },
        { name: "email", label: "Email", type: "email" },
        { name: "password", label: "Password", type: "password" },
        {
          name: "user_role",
          label: "User Role",
          type: "select",
          options: [
            { _id: 1, name: "Admin", value: "admin" },
            { _id: 2, name: "GM", value: "gm" },
          ],
        },
      ],
      submitButtonLabel: "Login",
    },
    loading:false
  };
  return (
    <>
      <ToastContainer />
      <AddComponent
        state={state}
        page={"Add Admin"}
        levels={[
          { text: "Workspaces", link: "/workspaces" },
          { text: "Projects List", link: "/workspace/projects" },
        ]}
      />
    </>
  );
};

export default AddAdmin;
