import React, {StrictMode, useContext, useState} from "react";
import { Button, Modal } from "react-bootstrap";
import Header from "../../component/header";
import Jumbotran from "../../component/Jumbotran";
import ProfileCard from "../../component/ProfileCard";
import Sidemenu from "../../component/sidemenu";
import Footer from "../../pages/footer";
import { FormComponent } from "../../component/formElements/FormComponent";
import * as Yup from "yup";
import {toast, ToastContainer} from "react-toastify";
import api from "../../api/api";
import {useLocation, useNavigate} from "react-router";
import {Context} from "../../context/ContextProvider";
import Loader from "../Loader";

const EditComponent = (props) => {
  const [tagModal, setTagModal] = useState(false);
  const {userData}=useContext(Context);
  const{data,loading}=userData;
  const {getAddAdminUserHandler} = useContext(Context)
  const navigate = useNavigate();
const {state,level=[]}=props;

//   const state = {
//     data: {
//       initialValues: {
//         name:data.name,
//         email: data.email,
//       },
//       validationSchema: Yup.object().shape({
//         name: Yup.string()
//           .min(2, "Too Short!")
//           .max(50, "Too Long!")
//           .required("Username is Required"),
//         email: Yup.string()
//           .email("Invalid email")
//           .required("Email is Required")
//           .min(2, "Too Short!")
//           .max(50, "Too Long!"),
//       }),
//       submitHandler: async (values) => {
//         const data = {...values,gid:""}
//         // same shape as initial values
//         const response = await getAddAdminUserHandler(data)
//         if (response.status === 201){
//               if (response.data.data.roles[0].name === "gm")
//                 navigate("/gm/users",{state:{toastmessage:response.data.message}})
//               else
//                 navigate("/admin/users",{state:{toastmessage:response.data.message}})
//         }else{
//           toast.error("Something went wrong")
//         }
//       },
//       inputs: [
//         { name: "name", label: "Name",type:"text" },
//         { name: "email", label: "Email",type:"email" },
//       ],
//       submitButtonLabel: "Update",
//     },
//   };
  return (
    <>
      <ToastContainer />
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                levels={level}
                page={props.page ? props.page : "Add Admin"}
                desc={props.page ? props.page : "Add Admin"}
              />
              <div className="page-inner">
                {/* <h4 className="page-title">{props.page ? 'Edit Admin' : 'Add Admin'}</h4> */}
                {
                  !loading ?
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card card-with-nav">
                            <div className="card-body">
                             
                              <FormComponent data={state.data} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    :

                <Loader />
                }

              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditComponent;
