import { useState } from "react";
import { ucWords } from "../../utils/util";

export const Select = ({
  name,
  changeHandler,
  onBlur,
  label,
  options,
  disabled,
  error,
  value,
}) => {
  const user = JSON.parse(localStorage.getItem("users"));

  const vacation = JSON.parse(localStorage.getItem("vacationdata"));
  const getrUserFromVaction = user?.filter(
    (item) => item?.id === vacation?.user_id
  );
  // console.log({ getrUserFromVaction: getrUserFromVaction[0] });

  return (
    <div className="col-md-6 mb-6">
      <div className="form-group">
        <label htmlFor="inputGroupSelect01">{label}</label>
        <select
          className="custom-select form-control"
          defaultValue={getrUserFromVaction && getrUserFromVaction[0]?.id}
          value={value}
          onBlur={onBlur}
          onChange={changeHandler}
          name={name}
          id="inputGroupSelect01"
        >
          {/* <option value="">Select {ucWords(label)}</option> */}
          {options.map((option) => (
            <>
              <option
                key={option._id}
                // selectedValue={getrUserFromVaction[0]?.id}
                value={option.value}
              >
                {option.name}
              </option>
            </>
          ))}
        </select>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};
