import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../src/assets/img/logo.png";
import { useForm } from "react-hook-form";
import api from "../api/api";
import { loginUrl } from "../api/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context, ContextProvider } from "../context/ContextProvider";

const Login = () => {
  const { userData, setUserData, loginHandler, loading } = useContext(Context);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  return (
    <>
      <ToastContainer />
      <section className="d-flex">
        <div className="slider--section d-none d-md-block">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="overlay1">
                  <img
                    className="d-block w-100"
                    src="https://demo.dashboardpack.com/architectui-react-pro/static/media/city.4ac39fb472e963dbca5d.jpg"
                    alt="First slide"
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className="text-nowrap">Perfect Balance</h5>
                    <p>
                      ArchitectUI is like a dream. Some think it's too good to
                      be true! Extensive collection of unified React Boostrap
                      Components and Elements.
                    </p>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="overlay3">
                  <img
                    className="d-block w-100"
                    src="https://demo.dashboardpack.com/architectui-react-pro/static/media/citynights.8df3ac24685306a68061.jpg"
                    alt="Third slide"
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className="text-nowrap">Complex, but lightweight</h5>
                    <p>
                      We've included a lot of components that cover almost all
                      use cases for any type of application.
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item ">
                <div className="overlay2">
                  <img
                    className="d-block w-100"
                    src="https://demo.dashboardpack.com/architectui-react-pro/static/media/citydark.0a91fa8083453354624f.jpg"
                    alt="Second slide"
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className="text-nowrap">
                      Scalable, Modular, Consistent
                    </h5>
                    <p>
                      Easily exclude the components you don't require.
                      Lightweight, consistent Bootstrap based styles across all
                      elements and components
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div className="form--section">
          <div className="h-100 d-flex bg-white justify-content-center align-items-center">
            <div className="mx-auto app-login-box col-sm-12 col-md-10  col-lg-9">
              <div className="px-2">
                <h2>Welcome back,</h2>
                <p>Please sign in to your account.</p>
                <hr />
              </div>
              <form
                onSubmit={handleSubmit((params) =>
                  loginHandler(params, navigate)
                )}
              >
                <Row className="">
                  <Col lg="6">
                    <div className="form-group">
                      <label>Email address</label>
                      <input
                        {...register("email", { required: true })}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email"
                      />
                      {errors.email && (
                        <span className={"text-danger"}>Email is required</span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        {...register("password", { required: true })}
                        type="password"
                        className="form-control"
                        id="exampleInputpassword1"
                        aria-describedby="passwordHelp"
                        placeholder="Enter Password"
                      />
                      {errors.password && (
                        <span className={"text-danger"}>
                          Password is required
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="form-check d-flex align-items-center">
                      <input
                        name="check"
                        id="exampleCheck"
                        type="checkbox"
                        className="form-check-input ml-0"
                      />
                      <label className="form-check-label mb-n2 mx-2 form-label">
                        Keep me logged in
                      </label>
                    </div>
                  </Col>
                </Row>
                <hr />
                <div className="text-right">
                  <button type="submit" className="btn btn-primary btn-lg">
                    {loading ? <>Please Wait...</> : <>Login to Dashboard </>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
