import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Context } from "../../context/ContextProvider";
import EditComponent from "../../component/formElements/EditComponent";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Loader from "../../component/Loader";
import Jumbotran from "../../component/Jumbotran";
import Header from "../../component/header";
import Sidemenu from "../../component/sidemenu";
import Footer from "../footer";
import { FormComponent } from "../../component/formElements/FormComponent";

const EditCategoryResource = () => {
  const {
    state,
    state: { vacation = {} },
  } = useLocation();

  const [loading, setLoading] = useState(false);
  const [guests, setGuests] = useState([]);
  const [selectGuests, setSelectGuests] = useState([]);
  const [users, setUsers] = useState([]);
  const [appGuests, setAppGuests] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [categories, setCategories] = useState([]);
  const { getAppUsers, getGuests, getAdmins, updateVacation, getCategories } =
    useContext(Context);

  const navigate = useNavigate();
  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    if (vacation.parent_id) {
      getCategories().then((res) => {
        setCategories([...res.data.data]);
        setLoading(false);
      });
    }
    getAppUsers().then((res) => {
      setUsers([...res.data.data]);
    });
    getGuests().then((res) => {
      setAppGuests([...res.data.data]);
    });
    getAdmins().then((res) => {
      setAdmins([...res.data.data]);
      setLoading(false);
    });
    vacation.vacation_guests &&
      vacation.vacation_guests.map((item) => {
        setSelectGuests([...selectGuests, item]);
      });
    console.log({ selectGuests });
    return () => {
      controller.abort();
    };
  }, []);
  //   let vacationStatus = [
  //     ,
  //     {
  //       id: "1",
  //       name: "Pending",
  //       value: "1",
  //       selected: true,
  //     },
  //     {
  //       id: "2",
  //       name: "Approved",
  //       value: "2",
  //       selected: "",
  //     },
  //   ];
  //   const stateEdit = {
  //     data: {
  //       initialValues: {
  //         title: vacation.title,
  //         destination: vacation.destination,
  //         vibe: vacation.vibe,
  //         vacation_status: vacation.vacation_status,
  //         guests_count: vacation.guests_count,
  //         budget: vacation.budget,
  //         start_date: vacation.start_date,
  //         end_date: vacation.end_date,
  //       },
  //       validationSchema: Yup.object().shape({
  //         title: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  //         start_date: Yup.date().required(),
  //         end_date: Yup.date().required(),
  //       }),
  //       submitHandler: async (values) => {
  //         const data = { ...values };
  //         data["guests"] = [];
  //         guests &&
  //           guests.map((guest, key) => {
  //             data["guests"][key] = guest.value;
  //           });
  //         // console.log(data['guests'])
  //         // same shape as initial values
  //         const response = await updateVacation(vacation.id, data);
  //         if (response.status === 200) {
  //           navigate("/vacations", {
  //             state: { toastmessage: response.data.message },
  //           });
  //         } else {
  //           toast.error("Something went wrong");
  //         }
  //       },
  //       inputs: [
  //         { name: "title", label: "Title", type: "text" },
  //         { name: "destination", label: "Destination", type: "text" },
  //         { name: "vibe", label: "Vibe", type: "text" },

  //         {
  //           name: "vacation_status",
  //           label: "Status",
  //           type: "select",
  //           options:
  //             vacationStatus &&
  //             vacationStatus.map((user, key) => ({
  //               _id: user.id,
  //               name: user.name,
  //               value: user.id,
  //               selected: vacation.user_id === user.id,
  //             })),
  //         },
  //         { name: "guests_count", label: "Guest Count", type: "number" },
  //         { name: "budget", label: "Budget", type: "number" },
  //         { name: "start_date", label: "Start Date", type: "date" },
  //         { name: "end_date", label: "End Date", type: "date" },
  //         {
  //           name: "user_id",
  //           label: "App User",
  //           type: "select",
  //           options:
  //             users &&
  //             users.map((user, key) => ({
  //               _id: user.id,
  //               name: user.name,
  //               value: user.id,
  //               selected: vacation.user_id === user.id,
  //             })),
  //         },
  //         {
  //           name: "admin_id",
  //           label: "Admin / GM",
  //           type: "select",
  //           options:
  //             admins &&
  //             admins.map((admin, key) => ({
  //               _id: admin.id,
  //               name: admin.name,
  //               value: admin.id,
  //               selected: vacation.admin_id == admin.id,
  //             })),
  //         },
  //         {
  //           name: "category_id",
  //           label: "Resource Category",
  //           type: "select",
  //           options:
  //             categories &&
  //             categories.map((admin, key) => ({
  //               _id: admin.id,
  //               name: admin.name,
  //               value: admin.id,
  //               selected: vacation.category_id == admin.id,
  //             })),
  //         },
  //         // {
  //         //     name: "guests",
  //         //     label: "Guests",
  //         //     type: "multiSelect",
  //         //     selectedValue: selectGuests ?? [],
  //         //     options:
  //         //         appGuests && appGuests.map((user, key) => (
  //         //             {_id: user.id, name: user.name, value: user.id, selected: vacation.user_id === user.id}
  //         //         ))
  //         //     ,
  //         //     onSelect: (data)=>{
  //         //         setGuests(data)
  //         //         console.log({guests})
  //         //     }
  //         // },
  //         {
  //           name: "notes",
  //           rows: 3,
  //           label: "Notes",
  //           type: "textarea",
  //           content: vacation.notes,
  //         },
  //       ],
  //       submitButtonLabel: "Update",
  //     },
  //     loading: loading,
  //   };

  console.log(vacation);
  return (
    <>
      <>
        <ToastContainer />
        <div className="App">
          <div className="wrapper">
            <Header />
            <Sidemenu />
            <div className="main-panel">
              <div className="container">
                <Jumbotran
                  page={"Edit Requested Resource"}
                  desc={"Edit Requested Resource"}
                />
                <div className="page-inner">
                  <div className="col-md-8 mx-auto">
                  {
                    vacation?.resource_fields?.map((item)=>{
                      return(
                        <div className="row">
                          {item.value.type= 'text' && <div><input className="form-control " /></div>}
                        </div>
                      )
                    })
                  }
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default EditCategoryResource;
