import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ProfileComp from "../component/ProfileComp";
import { Context } from "../context/ContextProvider";
import AddUser from "../pages/Users/AddUser";
import EditUser from "../pages/Users/EditUser";
import Admins from "../pages/Admins/Admins";
import AddAdmin from "../pages/Admins/AddAdmin";
import WorkSpace from "../pages/WorkSpaces/WorkSpace";
import Gms from "../pages/Gms";
import Home from "../pages/Home";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Register from "../pages/Register";
import Settings from "../pages/Setting";
import Users from "../pages/Users/Users";
import Projects from "../pages/WorkSpaces/Projects/Projects";
import EditAdmin from "../pages/Admins/EditAdmin";
import AllVacations from "../pages/Vacations/AllVacations";
import SingleVacation from "../pages/Vacations/SingleVacation";
import EditVacation from "../pages/Vacations/EditVacation";
import AllCategories from "../pages/Categories/AllCategories";
import EditCategory from "../pages/Categories/EditCategory";
import AddCategory from "../pages/Categories/AddCategory";
import TermCondition from "../pages/TermCondition";
import SingleVacationCategory from "../pages/Vacations/SingleVacationCategory";
import EditCategoryResource from "../pages/Vacations/EditCategoryResource";

const RootNavigation = () => {
  const { userData } = useContext(Context);

  useEffect(() => {}, [userData]);



  return (
    <>
      <Router>
        <Routes>
          {userData && userData !== null && userData !== {} && Object.keys(userData).length ? (
            <>

           
              <Route exact path="/" element={<Home />} />
              <Route exact path="/dashboard" element={<Home />} />
              <Route exact path="/profile" element={<ProfileComp />} />
              <Route exact path="/setting" element={<Settings />} />
              {/* <Route exact path="/add-cause" element={<AddCause />} /> */}
              {/* <Route
            exact
            path="/edit-cause"
            element={<AddCause page="edit" />}
          /> */}
              <Route exact path="/workspaces" element={<WorkSpace />} />
              <Route exact path="/workspace/projects" element={<Projects />} />
              <Route exact path="/admin/users" element={<Admins />} />
              <Route exact path="/admin/user/edit" element={<EditAdmin />} />
              <Route exact path="/admin/users/add" element={<AddAdmin />} />
              <Route exact path="/gm/users" element={<Gms />} />
              <Route exact path="/app/users" element={<Users />} />
              <Route exact path="/add-user" element={<AddUser />} />
              <Route exact path="/app/user/edit-user" element={<EditUser />} />

              <Route exact path="/vacations" element={<AllVacations />} />
              <Route exact path="/vacation/:id" element={<SingleVacation />} />
              <Route
                exact
                path="/category-request/:id"
                element={<SingleVacationCategory />}
              />
              <Route
                exact
                path="/vacation/:id/edit"
                element={<EditVacation />}
              />

              <Route
                exact
                path="/category-request/:id/edit"
                element={<EditCategoryResource />}
              />
              <Route exact path="/category" element={<AllCategories />} />
              <Route exact path="/category/create" element={<AddCategory />} />
              <Route
                exact
                path="/category/:id/edit"
                element={<EditCategory />}
              />
              <Route exact path="/term-condition" element={<TermCondition />} />
              <Route path="*" element={<NotFound />} />
              {/* <Route
                exact
                path="/edit-user"
                element={<AddUser page="edit" />}
              /> */}
              {/* <Route exact path="/donations" element={<Donations />} />
              <Route exact path="/transaction" element={<Transaction />} />
              <Route exact path="/contact-us" element={<Contact />} /> */}
            </>
          ) : (
            <>
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/" element={<Login />} />
              <Route exact path="/term-condition" element={<TermCondition />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}
        </Routes>
      </Router>
    </>
  );
};

export default RootNavigation;
