import React, { useContext, useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../../component/header";
import Sidemenu from "../../component/sidemenu";
import Jumbotran from "../../component/Jumbotran";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import { Context } from "../../context/ContextProvider";
import Loader from "../../component/Loader";

const WorkSpace = () => {
  //states
  const [workspaces, setWorkSpaces] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getWorkSpacesHandler, getWorkSpaceProjectsHandler } =
    useContext(Context);
  const navigate = useNavigate();
  const deleteUser = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Poof! Your user has been deleted!", {
          icon: "success",
        });
      }
    });
  };
  const gotoTransaction = (e) => {
    navigate("/transaction");
  };

  const [tagModal, setTagModal] = useState(false);
  const showTagModal = () => {
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };

  const workSpaceHandler = (workspace) => {
    getWorkSpaceProjectsHandler(workspace, navigate);
  };
  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    isMounted &&
      getWorkSpacesHandler().then((response) => {
        setWorkSpaces(response);
        setLoading(false);
      });
    return () => {
      return (isMounted = false);
    };
  }, []);
  return (
    <>
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran page="WorkSpace" desc="Workspaces List" />
              <div className="page-inner">
                {/* <h4 className="page-title">WorkSpace</h4> */}
                {!loading && (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-hover table-head-bg-primary table-sm-responsive mt-2">
                          <thead>
                            <tr>
                              <th colSpan={"1"}>#</th>
                              <th colSpan={"1"}>GID</th>
                              <th colSpan={"1"}>Name</th>
                              <th colSpan={"1"}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {workspaces && workspaces.length > 0 ? (
                              workspaces.map((item, index) => (
                                <React.Fragment key={item.gid}>
                                  <tr
                                    style={{ cursor: "pointer" }}
                                    onClick={() => workSpaceHandler(item)}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{item.gid}</td>
                                    <td>{item.name}</td>

                                    <td>
                                      {/*<span*/}
                                      {/*    onClick={() => navigate('/edit-cause')}*/}
                                      {/*    className="p-2 btn-warning"*/}
                                      {/*    style={{margin: "4px", borderRadius: "4px"}}*/}
                                      {/*>*/}
                                      {/*  <i className="fa fa-edit"></i>*/}
                                      {/*</span>*/}
                                      <span
                                        className="p-2 btn-info"
                                        onClick={() => workSpaceHandler(item)}
                                        style={{
                                          margin: "4px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </span>
                                      {/*    <span*/}
                                      {/*        onClick={(e) => deleteUser(e)}*/}
                                      {/*        className="p-2 btn-danger"*/}
                                      {/*        style={{ margin: "4px", borderRadius: "4px" }}*/}
                                      {/*    >*/}
                                      {/*  <i className="fa fa-trash"></i>*/}
                                      {/*</span>*/}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                            ) : (
                              <>
                                <tr className="text-center">
                                  <td colSpan="5">No workspace available.</td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
                <Loader loading={loading} />
              </div>
            </div>

            <Footer />
          </div>

          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>User Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Title:</strong>
                  </div>
                  <div className="mx-2">Quality Education</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Description:</strong>
                  </div>
                  <div
                    className="mx-2 mb-3"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit, Lorem Ipsum is simply
                    dummy text of the printing and typesetting industry. Neque
                    porro quisquam est qui dolorem ipsum quia dolor sit amet,
                    consectetur, adipisci velit, Lorem Ipsum is simply dummy
                    text of the printing and typesetting industry. Neque porro
                    quisquam est qui dolorem ipsum quia dolor sit amet,
                    consectetur, adipisci velit, Lorem Ipsum is simply dummy
                    text of the printing and typesetting industry. Neque porro
                    quisquam est qui dolorem ipsum quia dolor sit amet,
                    consectetur, adipisci velit, Lorem Ipsum is simply dummy
                    text of the printing and typesetting industry.
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Amount Raised:</strong>
                  </div>
                  <div className="mx-2">$2,000</div>
                </div>

                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Total Amount:</strong>
                  </div>
                  <div className="mx-2">$5,000</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1">
                    <strong>Status:</strong>
                  </div>
                  <div className="mx-2">Active</div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default WorkSpace;
