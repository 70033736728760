import axios from "axios";
let userToken;
const userData = JSON.parse(localStorage.getItem("userData"));

if (userData) {
  userToken = userData?.token;
}
const get = (apiUrl) => {
  return axios(apiUrl, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response.json();
  });
};

const post = async (apiUrl, data) => {
  try {
    const response = await axios(apiUrl, {
      method: "POST",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    return await response;
  } catch (e) {
    console.log({ e });
    return e.response;
  }
};

const authGet = async (apiUrl) => {
  try {
    const response = await axios(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userData"))?.token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    return await response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

const authPost = async (apiUrl, data, method) => {
  console.log(`FAIZI ${JSON.parse(localStorage.getItem("userData"))?.token}`)
  try {
    const response = await axios(apiUrl, {
      method: method ?? "POST",
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userData"))?.token}`,
      },
    });
    return await response;
  } catch (e) {
    return e.response;
  }
};

export default { get, post, authGet, authPost };
