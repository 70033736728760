import React from "react";
import { ToastContainer } from "react-toastify";
import Footer from "../../pages/footer";
import Header from "../header";
import Jumbotran from "../Jumbotran";
import Sidemenu from "../sidemenu";
import { FormComponent } from "./FormComponent";
import Loader from "../Loader";

const AddComponent = (props) => {
  const { state, levels } = props;
  return (
    <>
      <ToastContainer />
      <div className="App">
        <div className="wrapper">
          <Header />
          <Sidemenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                levels={levels}
                page={props.page ? props.page : "Add Admin"}
                desc={props.page ? props.page : "Add Admin"}
              />
              <div className="page-inner">
                {/* <h4 className="page-title">{props.page ? 'Edit Admin' : 'Add Admin'}</h4> */}
                {!state.loading ? (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-with-nav">
                          <div className="card-body">
                            <FormComponent data={state.data} lodaer={props.lodaer} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddComponent;
