import { useContext } from "react";
import "./App.css";
import "./assets/css/atlantis.css";
import "./assets/css/demo.css";

import {Context, ContextProvider} from "./context/ContextProvider";
import RootNavigation from "./navigation/RootNavigation";

function App() {
  return (
    <ContextProvider>
      <RootNavigation />
    </ContextProvider>
  );
}

export default App;
