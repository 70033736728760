import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import AddComponent from "../../component/formElements/AddComponent";
import { Context } from "../../context/ContextProvider";

const AddCategory = (props) => {
  const navigate = useNavigate();
  const { addCategories, getCategories } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const [fields, setFields] = useState([]);
  const [alreadyAdded, setAlreadyAdded] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: "",
    parent_id: "",
  });
  const [validationSchemaRaw, setValidationSchemaRaw] = useState({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is Required"),
    parent_id: Yup.number(),
  });
  const [fieldIndex, setFieldIndex] = useState(0);
  const [fieldType, setFieldType] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getCategories().then((res) => {
      setCategories([...res.data.data]);
      setFields([
        { name: "name", label: "Name", type: "text" },
        {
          name: "parent_id",
          label: "Parent",
          type: "select",
          options:
            [...res.data.data] &&
            [...res.data.data].map((category__, key) => ({
              _id: category__.id,
              name: category__.name,
              value: category__.id,
            })),
        },
        {
          name: "field_type",
          label: "New Field",
          type: "select",
          options: [
            { _id: 1, name: "Text Field", value: "text" },
            { _id: 2, name: "Number Field", value: "number" },
            { _id: 3, name: "Date Field", value: "date" },
            { _id: 4, name: "Time Field", value: "time" },
            { _id: 6, name: "Text Area Field", value: "textarea" },
            { _id: 7, name: "Single Image", value: "image" },
            { _id: 8, name: "Multiple Images", value: "images" },
          ],
        },
      ]);
      setLoading(false);
    });
    setFieldIndex(0);
  }, []);

  const state = {
    data: {
      initialValues: initialValues,
      // validationSchema: validationSchema,
      validationSchema: Yup.object().shape({
        ...validationSchemaRaw,
      }),
      submitHandler: async (values) => {
        const data = { ...values };

        let filtered_keys = (obj, filter) => {
          let key,
            keys = [];
          for (key in obj)
            if (obj.hasOwnProperty(key) && filter.test(key))
              keys.push(obj[key]);
          return keys;
        };

        data["filteredLabels"] = filtered_keys(data, /fields_label/);
        data["filteredTypes"] = filtered_keys(data, /fields_type/);
        console.log({ data });
        const response = await addCategories(data);
        if (response.status === 201) {
          navigate("/category", {
            state: { toastmessage: response.data.message },
          });
        } else {
          toast.error("Something went wrong");
        }
      },
      inputs: fields,
      addField: {
        label: "Add Field",
        clickHandler: function (params = "") {
          if (
            !(
              (Object.values(alreadyAdded).includes("image") &&
                params.values.field_type === "image") ||
              (Object.values(alreadyAdded).includes("images") &&
                params.values.field_type === "images")
            )
          ) {
            let labelField = `fields_label_${fieldIndex}`;
            let labelType = `fields_type_${fieldIndex}`;
            let object = {
              type: "custom",
              fieldIndex: fieldIndex,
              fields: [
                {
                  name: labelField,
                  label: "Field Label",
                  placedHolder: "Field Label",
                  type: "text",
                  value: "Field Label for " + params.values.field_type,
                },
                {
                  name: labelType,
                  label: "Field Type",
                  readonly: true,
                  type: "text",
                  value: params.values.field_type,
                },
              ],
            };
            let initVals = {
              ...params.values,
              [labelField]: "Field Label for " + params.values.field_type,
              [labelType]: params.values.field_type,
            };

            setFields([...state.data.inputs, object]);
            setInitialValues(initVals);

            setValidationSchemaRaw((object) => ({
              ...object,
              [labelField]: Yup.string(),
              [labelType]: Yup.string(),
            }));

            setFieldIndex(fieldIndex + 1);
            setAlreadyAdded([...alreadyAdded, params.values.field_type]);
          } else {
            alert("Cant add Single Image or Multiple Images more then once");
          }
        },
      },
      submitButtonLabel: "Add Category",
    },
    loading: loading,
  };
  return (
    <>
      <AddComponent
        state={state}
        page={"Add Category"}
        levels={[{ text: "Resource Categories", link: "/category" }]}
      />
    </>
  );
};

export default AddCategory;
