import React from "react";

export default function Input({ name,defaultValue,placedHolder,readonly, label, changeHandler, value, error, onBlur,type="text" }) {
  return (
    <div className="col-md-6 mb-3">
      <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
          type={type}
          name={name}
          // defaultValue={defaultValue ?? ""}
          value={value}
          readOnly={readonly}
          onChange={changeHandler}
          className="form-control"
          id={name}
          onBlur={onBlur}
          placeholder={placedHolder ?? `Enter ${name}`}
      />
      {error && <div className="alert alert-danger mt-3 p-2">{error}</div>}
    </div>
    </div>
  );
}
