import React, { useContext, useEffect, useState } from "react";
import Header from "../../component/header";
import Jumbotran from "../../component/Jumbotran";
import Sidemenu from "../../component/sidemenu";
import { Context } from "../../context/ContextProvider";
import Footer from "../footer";
import { useNavigate } from "react-router";
import Loader from "../../component/Loader";
import swal from "sweetalert";
import { getAllVacationsUrl } from "../../api/constants";

const AllVacations = () => {
  const [vacations, setVacations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#002866");
  const { getAllVacations, deleteVacation } = useContext(Context);

  const navigate = useNavigate();
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    getAllVacations().then((res) => {
      let array = res?.data?.data?.reverse();
      setVacations(array);
      setLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, []);
  const allVacation = () => {
    getAllVacations().then((res) => {
      let array = res?.data?.data?.reverse();
      setVacations(array);
      setLoading(false);
    });
  };
  console.log({ vacations });
  const deletevacationHandle = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(id);
        deleteVacation(id, getAllVacationsUrl).then((res) => {
          swal("Vacation deleted successfully.", {
            icon: "success",
          });

          setLoading(false);
          allVacation();
          navigate("/vacations");
        });
      }
    });
  };

  useEffect(() => {}, [vacations]);
localStorage.removeItem('image')
  return (
    <div className="App">
      <div className="wrapper">
        <Header />
        <Sidemenu />
        <div className="main-panel">
          <div className="container">
            <Jumbotran page="Vacations" desc="vacations" />
            <div className="page-inner">
              {/* <h4 className="page-title">User</h4> */}
              {!loading && (
                <>
                  <div className="row">
                    <div className="col-md-12" style={{ overflowX: "scroll" }}>
                      <table className="table table-hover table-head-bg-primary table-sm-responsive mt-2">
                        <thead className="text-nowrap">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">User</th>
                            <th scope="col">Assigned To</th>
                            <th scope="col">Destination</th>
                            <th scope="col">Vibe</th>
                            <th scope="col">Status</th>
                            <th scope="col">Guest</th>
                            {/* <th scope="col">Budget</th>
                            <th scope="col">Notes</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th> */}
                            <th scope="col">time</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody className="w-auto">
                          {vacations && vacations?.length > 0 ? (
                            <>
                              {vacations?.map((v, i) => (
                                <tr className="text-nowrap" key={v?.id}>
                                  <td>{i + 1}</td>
                                  <td>{v?.title}</td>
                                  <td>{v?.user ?? "N/A"}</td>
                                  <td className={"text-center"}>
                                    {v?.admin ?? "N/A"}
                                  </td>
                                  <td>{v?.destination}</td>
                                  <td>{v?.vibe ?? "N/A"}</td>
                                  <td>
                                    {v.vacation_status == 1
                                      ? "Pending"
                                      : "Approved"}
                                  </td>
                                  <td>{v?.guests_count ?? "N/A"}</td>
                                  {/* <td>{v?.budget ?? "N/A"}</td>
                                  <td>{v?.notes ?? "N/A"}</td>
                                  <td>{v?.start_date ?? "N/A"}</td>
                                  <td>{v?.end_date ?? "N/A"}</td> */}
                                  <td>{v?.time}</td>
                                  <td>
                                    <span
                                      onClick={() => {
                                        navigate(
                                          "/vacation/" + v?.id + "/edit",
                                          {
                                            state: { vacation: v },
                                          }
                                        );
                                        localStorage.setItem(
                                          "vacationdata",
                                          JSON.stringify(v)
                                        );

                                      }}
                                      className="p-2 btn-warning"
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </span>
                                    <span
                                      className="p-2 btn-info"
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigate("/vacation/" + v?.id, {
                                          state: { vacation: v },
                                        });
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </span>
                                    <span
                                      onClick={() =>
                                        deletevacationHandle(v?.id)
                                      }
                                      className="p-2 btn-danger"
                                      style={{
                                        margin: "4px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-trash"></i>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr className="text-center">
                                <td colSpan="8">No vacation available.</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
              <Loader loading={loading} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AllVacations;
